import React, { Component } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import VisibilityToggle from "../VisibilityToggle/VisibilityToggle";
import Button from "../Button/Button";
import { Center } from "@operata/adagio";

import CardAlternative from "../Card/CardAlternative";
import ApiTokenConfig from "./ApiTokenConfig";
import {
  CONFIG_CREATED,
  CONFIG_RECEIVED,
  createOrUpdateConfig,
  ERROR_CONFIG_CREATED,
  ERROR_CONFIG_RECEIVED,
  fetchConfig
} from "../../actions/config";
import {
  showSnackbarMessage,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS
} from "../../actions/snackbar";
import ChipInput from "material-ui-chip-input";
import _ from "lodash";
import { auth } from "../../auth/Auth";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { validate } from "./validation";
import SoftphoneAllowlistingConfig from "./SoftphoneAllowlistingConfig";
import Redactionconfig from "./RedactionConfig";
import Orchestrator from "./Orchestrator";
import CloudCollector from "./CloudCollector";
import { findFormLabelByValue } from "../../utils/formHelpers";

const ConfigGroup = ({ children }) => {
  return <>{children}</>;
};

ConfigGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ])
};

function RenderEditor(props) {
  const { field, onChange, value, error, label } = props;

  if (field.key === "SOFTPHONE_WHITELISTED_ORIGINS") {
    return (
      <SoftphoneAllowlistingConfig
        error={error}
        label={label}
        value={value}
        onChange={onChange}
      />
    );
  }

  return (
    <>
      {field.type === "string" && (
        <TextField
          error={error}
          label={label}
          name={field.key}
          className="textfield"
          onChange={event => {
            onChange(event.target.value);
          }}
          variant="outlined"
          value={props.value}
        />
      )}

      {field.type === "list" && (
        <FormControl variant="outlined" className="selectfield">
          <InputLabel htmlFor="output-helper">{field.title}</InputLabel>
          <Select
            name={field.key}
            variant="outlined"
            value={value}
            onChange={event => {
              onChange(event.target.value);
            }}
            input={
              <OutlinedInput name="method" id={"method-helper-" + field.key} />
            }
          >
            {field.options.map((option, i) => {
              return (
                <MenuItem
                  key={field.key + i}
                  value={typeof option === "object" ? option.value : option}
                >
                  {option.label || option}
                </MenuItem>
              );
            })}
          </Select>
          {field.helperTexts && (
            <p className="card__description">
              {field.helperTexts[value] ?? value}
            </p>
          )}
        </FormControl>
      )}

      {field.type === "boolean" && (
        <FormControlLabel
          control={
            <Checkbox
              checked={value === "true"}
              onChange={event => {
                onChange(event.target.checked ? "true" : "false");
              }}
              name={field.key}
              value={value}
            />
          }
          label={field.fieldText}
          style={{ marginBottom: "20px" }}
        />
      )}

      {field.type === "array" && (
        <ChipInput
          error={error}
          className="tags__wrapper"
          label={label}
          placeholder="Type and press enter to add"
          value={value}
          onAdd={newTag => onChange(_.union(value, [newTag]))}
          onDelete={deletedTag => onChange(_.without(value, deletedTag))}
        />
      )}

      {field.type === "json" &&
        field.key === "REDACTION_CONFIG" && (
          <Redactionconfig label={label} value={value || {}} />
        )}
      {field.type === "json" &&
        field.key === "ORCHESTRATOR_VERSION" && <Orchestrator value={value} />}
      {field.type === "json" &&
        field.key === "CLOUD_COLLECTOR_VERSION" && (
          <CloudCollector value={value} />
        )}
    </>
  );
}

RenderEditor.propTypes = {
  field: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any,
  error: PropTypes.string,
  label: PropTypes.string
};
/**
 * ConfigEditor component for managing various configuration settings.
 * Handles different types of configuration fields including strings, booleans, arrays, and JSON.
 * Supports both editable and read-only modes based on user permissions.
 *
 * @component
 * @class
 */

let ConfigEditor = class ConfigEditor extends Component {
  /**
   * Creates an instance of ConfigEditor.
   * Initializes state and binds methods.
   *
   * @param {Object} props
   * @param {Array} props.fields - Configuration fields to render
   * @param {Function} props.createOrUpdateConfig - Callback to update configuration
   * @param {Function} props.fetchConfig - Callback to fetch configuration
   * @param {Function} props.showSnackbarMessage - Shows notification messages
   * @param {Object} props.lastConfig - Last updated configuration
   * @param {string} props.status - Current status of config operations
   * @param {boolean} props.loading - Loading state indicator
   */
  constructor(props) {
    super(props);
    this.state = {
      showSaveButton: false,
      newApiToken: null
    };

    this.props.fields.map(field => {
      this.setConfigInState(field.key, "");
    });

    this.handleSave = this.handleSave.bind(this);
  }
  /**
   * Sets a configuration value in the component state
   *
   * @param {string} key - Configuration key
   * @param {*} value - Value to set
   */
  setConfigValue(key, value) {
    console.log(`setConfigValue`, key, value);
    this.setConfigInState(key, value);
  }
  /**
   * Sets a nested JSON configuration value
   *
   * @param {string} key - Parent configuration key
   * @param {Object} value - Current JSON value
   * @param {string} childKey - Child key to update
   * @param {*} childValue - Value to set for child key
   */
  setConfigJSONValue(key, value, childKey, childValue) {
    value = this.getJSONValue(value);
    value[childKey] = childValue;
    console.log(`setConfigJSONValue`, key, value);
    this.setConfigInState(key, value);
  }

  getJSONValue(value) {
    if (!value || value.constructor !== {}.constructor) {
      value = {};
    }
    return value;
  }

  setConfigInState(key, value) {
    let keyValuePairs = { showSaveButton: true };
    keyValuePairs[key] = value;
    this.setState(keyValuePairs);
  }

  async handleSave() {
    let errors = {};

    errors = await validate(this.state, [], this);

    if (!errors["hasErrors"]) {
      this.props.fields.map(field => {
        if (auth.hasPermission(field.write)) {
          this.props.createOrUpdateConfig(
            { key: field.key, value: this.state[field.key] },
            field.type
          );
        }
      });
    } else {
      this.props.showSnackbarMessage(
        SNACKBAR_ERROR,
        "Config did not save. Please correct the error(s) shown before continuing."
      );
    }
  }

  componentDidMount() {
    this.props.fields.map(field => {
      this.props.fetchConfig(field.key, field.type);
    });
  }

  componentDidUpdate(prevProps) {
    const { lastConfig } = this.props;
    if (
      this.props.status === CONFIG_RECEIVED &&
      (!prevProps.lastConfig || lastConfig.key !== prevProps.lastConfig.key)
    ) {
      let keyValuePairs = {};
      keyValuePairs[lastConfig.key] = lastConfig.value;
      this.setState(keyValuePairs);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.status === CONFIG_CREATED) {
      nextProps.showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Config has been set successfully."
      );
    }

    if (nextProps.status === ERROR_CONFIG_CREATED) {
      nextProps.showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while setting the Config."
      );
    }

    if (nextProps.status === ERROR_CONFIG_RECEIVED) {
      nextProps.showSnackbarMessage(
        SNACKBAR_ERROR,
        "An error occurred while retreiving Configs."
      );
    }
    return prevState;
  }

  render() {
    const { fields } = this.props;

    return (
      <React.Fragment>
        {fields.map((field, i) => {
          let value = this.state[field.key] || field.default;

          return (
            <React.Fragment key={i}>
              {/* Regular write permission fields (non-API tokens) */}
              {auth.hasPermission(field.write) &&
                field.key !== "API_TOKENS" && (
                  <div className={`wrapper ${field.hide ? "hidden" : ""}`}>
                    <CardAlternative title={field.title}>
                      <VisibilityToggle>{field.description}</VisibilityToggle>
                    </CardAlternative>

                    {!field.children && (
                      <RenderEditor
                        key={field.key + i}
                        field={field}
                        onChange={value =>
                          this.setConfigValue(field.key, value)
                        }
                        value={value}
                        error={this.state["error_" + field.key]}
                        label={
                          this.state["errorMessage_" + field.key]
                            ? this.state["errorMessage_" + field.key]
                            : field.title
                        }
                      />
                    )}

                    {field.fields &&
                      field.fields.map(childField => {
                        value = this.getJSONValue(value, i);
                        return (
                          <>
                            <RenderEditor
                              key={childField.key + i}
                              field={childField}
                              onChange={childValue =>
                                this.setConfigJSONValue(
                                  field.key,
                                  value,
                                  childField.key,
                                  childValue
                                )
                              }
                              value={
                                value[childField.key] || childField.default
                              }
                              error={this.state["error_" + childField.key]}
                              label={
                                this.state["errorMessage_" + childField.key]
                                  ? this.state["errorMessage_" + childField.key]
                                  : childField.title
                              }
                            />
                          </>
                        );
                      })}
                  </div>
                )}

              {/* API Tokens section with write permission */}
              {auth.hasPermission(field.write) &&
                field.key === "API_TOKENS" && (
                  <Center width={"medium"}>
                    <ApiTokenConfig
                      values={value || []}
                      editable={true}
                      onCreate={async newToken => {
                        await this.setConfigValue(
                          field.key,
                          _.union(value, [newToken])
                        );
                        this.handleSave();
                      }}
                      onRevoke={async item => {
                        await this.setConfigValue(
                          field.key,
                          _.without(value, item)
                        );
                        this.handleSave();
                      }}
                    />
                  </Center>
                )}

              {/* Read-only section */}
              {!auth.hasPermission(field.write) &&
                auth.hasPermission(field.read) && (
                  <div className="wrapper">
                    <CardAlternative title={field.title}>
                      <VisibilityToggle>{field.description}</VisibilityToggle>
                    </CardAlternative>
                    {field.type === "json" && (
                      <RenderEditor
                        key={field.key + i}
                        field={field}
                        onChange={value =>
                          this.setConfigValue(field.key, value)
                        }
                        value={value}
                        error={this.state["error_" + field.key]}
                        label={
                          this.state["errorMessage_" + field.key]
                            ? this.state["errorMessage_" + field.key]
                            : field.title
                        }
                      />
                    )}
                    {field.type === "array" && (
                      <p className="card__description">{_.join(value, ", ")}</p>
                    )}
                    {field.type === "string" && (
                      <p className="card__description">{value}</p>
                    )}
                    {field.type === "list" && (
                      <FormControl
                        variant="outlined"
                        className="selectfield"
                        disabled
                      >
                        <InputLabel htmlFor="output-helper">
                          {field.title}
                        </InputLabel>
                        <Select
                          name={field.key}
                          variant="outlined"
                          value={value}
                        >
                          <MenuItem
                            key={field.key + value + "readonly"}
                            value={value}
                          >
                            {findFormLabelByValue(field.options, value)}
                          </MenuItem>
                        </Select>
                        {field.helperTexts && (
                          <p className="card__description">
                            {field.helperTexts[value] ?? value}
                          </p>
                        )}
                      </FormControl>
                    )}
                    {field.type === "boolean" && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            checked={value === "true" || value === true}
                            name={field.key}
                            value={value}
                          />
                        }
                        label={field.fieldText}
                      />
                    )}
                    {field.type === "json" &&
                      field.key === "API_TOKENS" && (
                        <ApiTokenConfig values={value || []} editable={false} />
                      )}
                  </div>
                )}
            </React.Fragment>
          );
        })}

        {/* Save button - only show for non-API token pages */}
        {!fields.some(field => field.key === "API_TOKENS") && (
          <div className="form__control form__control--buttons">
            {this.state["showSaveButton"] && (
              <Button
                asyncButton={true}
                buttonText={"Save"}
                className={"btnSolid btnSolid-no-margin"}
                onClick={this.handleSave}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
};

ConfigEditor.propTypes = {
  fields: PropTypes.array,
  createOrUpdateConfig: PropTypes.func,
  fetchConfig: PropTypes.func,
  showSnackbarMessage: PropTypes.func,

  lastConfig: PropTypes.object,
  status: PropTypes.string,
  loading: PropTypes.bool
};

const mapDispatchToProps = {
  createOrUpdateConfig,
  fetchConfig,
  showSnackbarMessage
};

const mapStateToProps = state => {
  return {
    lastConfig: state.config.config,
    loading: state.config.loading,
    status: state.config.status,
    random: state.config.random
  };
};

ConfigEditor = connect(
  // eslint-disable-line no-class-assign
  mapStateToProps,
  mapDispatchToProps
)(ConfigEditor);

export default ConfigEditor;
