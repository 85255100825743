import React, { useState } from "react";
import {
   ButtonRefactored as Button,
   InputText,
   Modal,
   Stack
} from "@operata/adagio";
import { DataRole } from "../../models/dataRole";

type EditRoleNameModalProps = {
   onClose: () => void;
   onSave: (role: DataRole) => void;
   role: DataRole;
};

export default function EditRoleNameModal({
                                               onClose,
                                               onSave,
                                               role
                                            }: EditRoleNameModalProps) {
   const [name, setName] = useState(role.name);

   const isFormValid = name.length > 0;

   return (
      <div>
         <Modal isOpen={true} onClose={onClose} width="medium">
            <Modal.Header border>Edit Role Name</Modal.Header>
            <Modal.Content>
               <Stack direction="column" gap="medium" padding="medium" block>
                  <InputText
                     width="100%"
                     label={"Name"}
                     value={name}
                     placeholder={""}
                     onChange={(e: any) => setName(e.target.value)}
                  />
               </Stack>
               <Stack direction="column" gap="medium" padding="medium" block />
            </Modal.Content>
            <Modal.Footer>
               <Stack direction="row" gap="8" justify="end">
                  <Button onClick={onClose} type="tertiary" size="large">
                     Cancel
                  </Button>
                  <Button
                     disabled={!isFormValid}
                     onClick={() => onSave({ ...role, name })}
                     type="primary"
                     size="large"
                  >
                     Update
                  </Button>
               </Stack>
            </Modal.Footer>
         </Modal>
      </div>
   );
}