import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchBillingAuditLogs } from "../../actions/billing";
import DownloadIcon from "@mui/icons-material/Download";

import {
  ButtonRefactored,
  Table,
  InputSelect,
  TableFilter,
  BrickCopy,
  Skeleton,
  Stack
} from "@operata/adagio";

import TablePagination from "../Group/TablePagination";

import { createCSVDownload } from "../Datatables/utils";

const downloadCSV = logs => {
  const columns = [
    {
      name: "Type",
      download: true
    },
    {
      name: "Customer ID",
      download: true
    },
    {
      name: "Customer Name",
      download: true
    },
    {
      name: "Group ID",
      download: true
    },
    {
      name: "Group Name",
      download: true
    },
    {
      name: "Sent Timestamp",
      download: true
    },
    {
      name: "Status",
      download: true
    },
    {
      name: "Data Sent",
      download: true
    }
  ];

  const data = logs.map(log => {
    return {
      data: [
        log.provider,
        log.customerID,
        log.customerName,
        log.groupID,
        log.groupName,
        log.sentAt,
        log.status,
        JSON.stringify(log.billingData, null, 2)
      ]
    };
  });

  const options = {
    downloadOptions: {
      filename: "tableDownload.csv",
      separator: ","
    }
  };

  createCSVDownload(columns, data, options);
};

const Paginate = ({ pageSize, page }) => array =>
  array.slice(pageSize * page, pageSize * (page + 1));

let BillingAuditLogs = ({
  fetchBillingAuditLogs,
  billingAuditLogs,
  loading
}) => {
  const customerNames = [
    ...new Set(billingAuditLogs.map(log => log.customerName))
  ].filter(a => a);

  const groupTypes = [
    ...new Set(billingAuditLogs.map(log => log.provider))
  ].filter(a => a);

  const [customerNameFilter, setCustomerNameFilter] = useState(null);
  const [groupTypeFilter, setGroupTypeFilter] = useState(null);
  const [sort, setSort] = useState("desc");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(
    () => {
      fetchBillingAuditLogs();
    },
    [fetchBillingAuditLogs]
  );

  let filteredGroups = billingAuditLogs;

  filteredGroups = customerNameFilter
    ? filteredGroups.filter(log => log.customerName === customerNameFilter)
    : filteredGroups;
  filteredGroups = groupTypeFilter
    ? filteredGroups.filter(log => log.provider === groupTypeFilter)
    : filteredGroups;

  const sortRows =
    sort === "desc"
      ? (a, b) => new Date(b.sentAt) - new Date(a.sentAt)
      : (a, b) => new Date(a.sentAt) - new Date(b.sentAt);
  const sortedGroups = filteredGroups.sort(sortRows);

  const paginatedGroups = Paginate({ pageSize, page })(sortedGroups);

  return (
    <>
      <TableFilter
        filters={
          <>
            <InputSelect
              placeholder="Customer Name"
              selectedValue={customerNameFilter}
              setSelectedValue={customerName => {
                setCustomerNameFilter(customerName);
                setPage(0);
              }}
            >
              {customerNames.map((customerName, index) => (
                <InputSelect.Option key={index} value={customerName} />
              ))}
            </InputSelect>
            <InputSelect
              placeholder="Group Type"
              selectedValue={groupTypeFilter}
              setSelectedValue={groupType => {
                setGroupTypeFilter(groupType);
                setPage(0);
              }}
            >
              {groupTypes.map((groupType, index) => (
                <InputSelect.Option key={index} value={groupType} />
              ))}
            </InputSelect>
            <ButtonRefactored
              size="medium"
              type="secondary"
              disabled={!customerNameFilter && !groupTypeFilter}
              onClick={() => {
                setGroupTypeFilter(null);
                setCustomerNameFilter(null);
                setPage(0);
              }}
            >
              Reset
            </ButtonRefactored>
          </>
        }
        actions={
          <ButtonRefactored
            type="secondary"
            size="medium"
            disabled={!billingAuditLogs.length}
            onClick={() => downloadCSV(billingAuditLogs)}
            iconAfter={<DownloadIcon />}
          >
            Download CSV
          </ButtonRefactored>
        }
      />
      <Table>
        <thead>
          <Table.Row>
            <Table.Header width="240">Customer ID</Table.Header>
            <Table.Header width="240">Customer Name</Table.Header>
            <Table.Header width="240">Group Name</Table.Header>
            <Table.Header width="auto" align="left">
              Type
            </Table.Header>
            <Table.Header
              width="auto"
              align="left"
              sortable
              sortIcon={
                sort === "desc" ? (
                  <Table.SortDescendingIcon fontSize="inherit" />
                ) : (
                  <Table.SortAscendingIcon fontSize="inherit" />
                )
              }
              onSortClick={() =>
                sort === "desc" ? setSort("asc") : setSort("desc")
              }
            >
              Sent Timestamp
            </Table.Header>
            <Table.Header width="auto" align="left">
              Status
            </Table.Header>
            <Table.Header width="240" align="left">
              Data Sent
            </Table.Header>
          </Table.Row>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: pageSize }, (_, i) => (
              <Table.Row key={i}>
                <Table.Cell width="240">
                  <Stack gap="8">
                    <Skeleton loading width={150} height={20} />
                    <Skeleton loading width={150} height={20} />
                  </Stack>
                </Table.Cell>
                <Table.Cell width="240">
                  <Stack gap="8">
                    <Skeleton loading width={94} height={20} />
                    <Skeleton loading width={140} height={20} />
                    <Skeleton loading width={140} height={20} />
                  </Stack>
                </Table.Cell>
                <Table.Cell width="auto" align="left">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell width="auto" align="left">
                  <Skeleton loading width={140} height={20} />
                </Table.Cell>
                <Table.Cell width="auto" align="left">
                  <Skeleton loading width={120} height={16} />
                </Table.Cell>
                <Table.Cell width="240" align="left">
                  <Skeleton loading width={300} height={73} />
                </Table.Cell>
              </Table.Row>
            ))
          ) : paginatedGroups.length ? (
            paginatedGroups.map((log, index) => (
              <Table.Row key={index}>
                <Table.Cell width="240">
                  {log.customerID ? (
                    <BrickCopy
                      icon={<BrickCopy.CopyIcon />}
                      onClick={() =>
                        navigator.clipboard.writeText(log.customerID)
                      }
                    >
                      {log.customerID}
                    </BrickCopy>
                  ) : (
                    <span>N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell width="240">
                  {log.customerName ? (
                    <BrickCopy
                      icon={<BrickCopy.CopyIcon />}
                      onClick={() =>
                        navigator.clipboard.writeText(log.customerName)
                      }
                    >
                      {log.customerName}
                    </BrickCopy>
                  ) : (
                    <span>N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell width="240">
                  {log.groupID ? (
                    <BrickCopy
                      title={log.groupName}
                      icon={<BrickCopy.CopyIcon />}
                      onClick={() => navigator.clipboard.writeText(log.groupID)}
                    >
                      {log.groupID}
                    </BrickCopy>
                  ) : (
                    <span>N/A</span>
                  )}
                </Table.Cell>
                <Table.Cell width="auto" align="left">
                  {log.provider}
                </Table.Cell>
                <Table.Cell width="auto" align="left" breakWord>
                  {log.sentAt}
                </Table.Cell>
                <Table.Cell width="auto" align="left">
                  {log.status}
                </Table.Cell>
                <Table.Cell width="240" align="left">
                  {JSON.stringify(log.billingData, null, 2)}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.RowEmpty colspan={6}>No data Found</Table.RowEmpty>
          )}
        </tbody>
      </Table>
      <TablePagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        page={page}
        setPage={setPage}
        count={filteredGroups.length}
      />
    </>
  );
};

BillingAuditLogs.propTypes = {
  fetchBillingAuditLogs: PropTypes.func,

  billingAuditLogs: PropTypes.array,
  loading: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  fetchBillingAuditLogs: fetchBillingAuditLogs
};

const mapStateToProps = state => ({
  billingAuditLogs: state.billing.billingAuditLogs || [],
  loading: state.progress.loading
});

// eslint-disable-next-line no-class-assign
BillingAuditLogs = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingAuditLogs);

export default BillingAuditLogs;
