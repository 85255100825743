import { featureToggles, FEATURES } from "../../auth/FearureToggles";
import { USER_ROLE_ADMIN, USER_ROLE_SUPER } from "../../auth/Auth";
export let AVAILABLE_APPLICATIONS = {};
export let AVAILABLE_TRIGGERS = {};
export let AVAILABLE_ACTIONS = {};

export const setFeatureToggleConstants = () => {
  AVAILABLE_APPLICATIONS["aws-connect"] = {
    image: require("../../shared/images/ccass-platform-logo.png"),
    icon: require("../../shared/images/ccass-platform-logo-icon.png"),
    application: "CCaaS Platform",
    enabled: ""
  };

  AVAILABLE_APPLICATIONS["jabra"] = {
    image: require("../../shared/images/jabra-logo.png"),
    icon: require("../../shared/images/jabra-logo-icon.png"),
    application: "Jabra",
    enabled: "disabled"
  };

  AVAILABLE_APPLICATIONS["operata-response"] = {
    image: require("../../shared/images/fello-colour.png"),
    icon: require("../../shared/images/fello-colour-icon.png"),
    application: "Operata Agent Messenger",
    enabled: "disabled"
  };

  if (
    featureToggles.isFeatureEnabled(FEATURES.WORKFLOW_APPLICATION_TYPE_GENESYS)
  ) {
    AVAILABLE_APPLICATIONS["genesys-pureCloud"] = {
      image: require("../../shared/images/genesys.jpg"),
      icon: require("../../shared/images/genesys.jpg"),
      application: "Genesys PureCloud",
      enabled: "disabled"
    };
  }

  if (
    featureToggles.isFeatureEnabled(FEATURES.WORKFLOW_APPLICATION_TYPE_TWILIO)
  ) {
    AVAILABLE_APPLICATIONS["twillio"] = {
      image: require("../../shared/images/twillio.png"),
      icon: require("../../shared/images/twillio-icon.png"),
      application: "Twillio Flex",
      enabled: "disabled"
    };
  }

  if (
    featureToggles.isFeatureEnabled(FEATURES.WORKFLOW_APPLICATION_TYPE_CISCO)
  ) {
    AVAILABLE_APPLICATIONS["cisco-webex"] = {
      image: require("../../shared/images/cisco.png"),
      icon: require("../../shared/images/packet-loss-icon.png"),
      application: "Cisco WebEx",
      enabled: "disabled"
    };
  }

  AVAILABLE_TRIGGERS["aws-connect-low-mos"] = {
    watchdogWatcher: true,
    displayText: "MOS",
    application: "aws-connect",
    image: require("../../shared/images/mos.png"),
    icon: require("../../shared/images/mos-icon.png"),
    description:
      "The Mean Opinion Score is an overall indicator of the health of your voice service. Low=Bad. High=Good.",
    triggerConditions: [
      {
        field: "threshold",
        thresholdDesc: "MOS <=",
        defaultThreshold: 3.5,
        type: "number",
        required: true
      }
    ]
  };

  AVAILABLE_TRIGGERS["aws-connect-high-packetloss"] = {
    watchdogWatcher: true,
    displayText: "Packet Loss",
    application: "aws-connect",
    image: require("../../shared/images/packet-loss.png"),
    icon: require("../../shared/images/packet-loss-icon.png"),
    description:
      "Those valuable network packets containing your customer's voice are being lost. Find out where.",
    triggerConditions: [
      {
        field: "threshold",
        thresholdDesc: "Packets Loss % >=",
        defaultThreshold: 1.0,
        type: "number",
        required: true
      }
    ]
  };

  AVAILABLE_TRIGGERS["aws-connect-high-jitter"] = {
    watchdogWatcher: true,
    displayText: "Jitter",
    application: "aws-connect",
    image: require("../../shared/images/jitter.png"),
    icon: require("../../shared/images/jitter-icon.png"),
    description:
      "Robotic sounding voice? Accelerated speech? Jitter is the likely culprit.",
    triggerConditions: [
      {
        field: "threshold",
        thresholdDesc: "Jitter >=",
        defaultThreshold: 30,
        type: "number",
        required: true
      }
    ]
  };

  AVAILABLE_TRIGGERS["aws-connect-high-latency"] = {
    watchdogWatcher: true,
    displayText: "Latency",
    application: "aws-connect",
    image: require("../../shared/images/latency.png"),
    icon: require("../../shared/images/latency-icon.png"),
    description:
      "The much loathed voice delay between you and your customer. Get closer.",
    triggerConditions: [
      {
        field: "threshold",
        thresholdDesc: "Latency >=",
        defaultThreshold: 400,
        type: "number",
        required: true
      }
    ]
  };

  AVAILABLE_TRIGGERS["aws-connect-agent-issue-reported"] = {
    watchdogWatcher: true,
    displayText: "Agent Reported Issue",
    application: "aws-connect",
    image: require("../../shared/images/agent-reported-issue.png"),
    icon: require("../../shared/images/agent-reported-issue-icon.png"),
    description: "The agent has reported issues during the call.",
    triggerConditions: [
      {
        field: "cause",
        thresholdDesc: "Category",
        defaultThreshold: "",
        type: "select",
        options: [
          "Softphone would not work",
          "Poor sound quality",
          "Delay in the conversation",
          "Call disconnected",
          "Customer could not hear me",
          "Could not hear the customer",
          "Missed call, the Softphone did not ring",
          "Something else"
        ],
        required: false
      },
      {
        field: "severity",
        thresholdDesc: "Severity",
        defaultThreshold: "",
        type: "select",
        options: ["Terrible", "Difficult", "Minor"],
        required: false
      },
      {
        field: "scenario",
        thresholdDesc: "Agent Comments",
        defaultThreshold: "",
        type: "text",
        required: false
      }
    ]
  };

  AVAILABLE_TRIGGERS["operata-response-yes"] = {
    displayText: "If the response is Yes",
    application: "operata-response",
    image: require("../../shared/images/yes.svg"),
    icon: require("../../shared/images/yes-icon.png"),
    description: "Triggered when end user has responded with Yes"
  };

  AVAILABLE_TRIGGERS["operata-response-no"] = {
    displayText: "If the response is No",
    application: "operata-response",
    image: require("../../shared/images/no.svg"),
    icon: require("../../shared/images/no-icon.png"),
    description: "Triggered when end user has responded with No."
  };

  AVAILABLE_TRIGGERS["jabra-high-crosstalk-high-rtt"] = {
    displayText: "Audio Delays",
    application: "jabra",
    image: require("../../shared/images/audio-delay.png"),
    icon: require("../../shared/images/audio-delay-icon.png"),
    description:
      "Higher than normal latency causing speech delay. Patience, grasshopper."
  };

  AVAILABLE_TRIGGERS["jabra-high-crosstalk-low-rtt"] = {
    displayText: "Crosstalk",
    application: "jabra",
    image: require("../../shared/images/crosstalk.png"),
    icon: require("../../shared/images/crosstalk-icon.png"),
    description:
      "Agent talking over the top of the customer. Take a breath to delight your customer."
  };

  AVAILABLE_TRIGGERS["jabra-high-noise"] = {
    displayText: "High Background Noise",
    application: "jabra",
    image: require("../../shared/images/high-noise.png"),
    icon: require("../../shared/images/high-noise-icon.png"),
    description: "Average background noise exceeding 50dB. Quiet on the set!"
  };

  // todo create an application type for this
  AVAILABLE_TRIGGERS["incorrect_headset"] = {
    displayText: "Incorrect Headset",
    application: "",
    image: require("../../shared/images/jabrastats.png"),
    icon: require("../../shared/images/jabrastats.png"),
    description: "The agent is using an incorrect headset."
  };

  AVAILABLE_TRIGGERS["heartbeat-telephony"] = {
    watchdogWatcher: true,
    permittedTo: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
    displayText: "Heartbeat",
    application: "aws-connect",
    image: require("../../shared/images/heartbeat.png"),
    icon: require("../../shared/images/heartbeat.png"),
    description:
      "Triggered when there are issues with Heartbeat status and call quality",
    triggerConditions: [
      {
        field: "status",
        thresholdDesc: "Status",
        defaultThreshold: [],
        type: "checkbox",
        options: [
          "Agent Error",
          "Call Failed",
          "Call Not Accepted by Agent",
          "Poor Call Quality"
        ],
        required: true
      }
    ]
  };

  if (featureToggles.isFeatureEnabled(FEATURES.EVENTBRIDGE_MODULE)) {
    AVAILABLE_TRIGGERS["eb-summary-data"] = {
      watchdogWatcher: true,
      displayText: "Contact Ended",
      application: "aws-connect",
      image: require("../../shared/images/high-noise.png"),
      icon: require("../../shared/images/high-noise-icon.png"),
      description: "Contact ended!"
    };

    AVAILABLE_TRIGGERS["eb-sod-test-data"] = {
      watchdogWatcher: true,
      displayText: "Start of the Day Test",
      application: "aws-connect",
      image: require("../../shared/images/start-of-day.png"),
      icon: require("../../shared/images/start-of-day.png"),

      description: "Start of the day test audio analysis data"
    };
  }

  AVAILABLE_ACTIONS["send-email"] = {
    displayText: "Send Email",
    image: require("../../shared/images/email.png"),
    icon: require("../../shared/images/email-icon.png"),
    enabled: ""
  };

  AVAILABLE_ACTIONS["invoke-webhook"] = {
    displayText: "Invoke Webhook",
    image: require("../../shared/images/webhook.png"),
    icon: require("../../shared/images/webhook-icon.png"),
    enabled: ""
  };

  AVAILABLE_ACTIONS["agent-assist-notification"] = {
    displayText: "Agent Notification",
    image: require("../../shared/images/agent-notifiction.png"),
    icon: require("../../shared/images/agent-notification-icon.png"),
    enabled: "disabled"
  };

  AVAILABLE_ACTIONS["operata-test"] = {
    displayText: "Operata Test",
    image: require("../../shared/images/discussion.png"),
    icon: require("../../shared/images/discussion-icon.png"),
    enabled: "disabled"
  };

  AVAILABLE_ACTIONS["send-to-slack"] = {
    displayText: "Slack",
    image: require("../../shared/images/slack.png"),
    icon: require("../../shared/images/slack-icon.png"),
    enabled: ""
  };

  AVAILABLE_ACTIONS["agent_alert"] = {
    displayText: "Agent Alert",
    image: require("../../shared/images/agent-notification.png"),
    icon: require("../../shared/images/agent-notification.png"),
    enabled: ""
  };

  AVAILABLE_ACTIONS["app-specific"] = {
    displayText: "App Specific",
    image: require("../../shared/images/tag.png"),
    icon: require("../../shared/images/tag.png"),
    enabled: "disabled"
  };

  if (featureToggles.isFeatureEnabled(FEATURES.EVENTBRIDGE_MODULE)) {
    AVAILABLE_ACTIONS["eventbridge"] = {
      image: require("../../shared/images/aws-eventbridge.png"),
      icon: require("../../shared/images/aws-eventbridge.png"),
      displayText: "Amazon Eventbridge",
      enabled: ""
    };
  }
};
