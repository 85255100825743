import {
  validateRequireField,
  validateURL,
  validateJSON,
  validateAlphanumericId
} from "../../Generic/core-validations";

export const validate = (values, requiredFields, component) => {
  const errors = {};

  validateRequireField(requiredFields, values, errors, component);

  if (values["actionId"] && values["actionId"].length > 0) {
    validateAlphanumericId("actionId", values, errors, component);
  }

  if (values["url"] && values["url"].length > 0) {
    validateURL("url", values, errors, component);
  }

  if (values["headers"] && values["headers"].length > 0) {
    validateJSON("headers", values, errors, component);
  }

  return errors;
};
