import React from "react";
import "./Canvas.scss";
import {
  useRouteMatch,
  withRouter,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { auth } from "../../auth/Auth";

let CanvasHome = props => {
  let nextPage = props.next.replace("@@group_id@@", auth.getCurrentGroupId());

  const location = window.location.href;

  const substrs = location.split("/");
  const index = substrs.length - 1;

  let locationSubstr = substrs[index];
  if (substrs.length > 1 && locationSubstr == "") {
    locationSubstr = substrs[index - 1];
  }
  if (
    locationSubstr != "" &&
    locationSubstr != "canvas" &&
    locationSubstr != "list"
  ) {
    const nextStr = nextPage.substring(0, nextPage.lastIndexOf("/"));
    nextPage = nextStr + "/" + locationSubstr;
  }

  if (locationSubstr != "" && locationSubstr == "list") {
    nextPage = "/dashboard/list";
  }

  return (
    <div
      className={"iframe__wrapper superset__iframe"}
      dangerouslySetInnerHTML={{
        __html:
          `<iframe id="dashboard_iframe" title="canvas" src=` +
          process.env.REACT_APP_BI_URL.replace(
            ".operata.io",
            auth.getRegionalUrlPrefix() + ".operata.io"
          ) +
          nextPage +
          ` height="600" width="800" content="upgrade-insecure-requests"></iframe>`
      }}
    />
  );
};

export const mapStateToProps = state => ({
  next: state.reports.next
});

const mapDispatchToProps = {};

// eslint-disable-next-line no-class-assign
CanvasHome = connect(
  mapStateToProps,
  mapDispatchToProps
)(CanvasHome);

CanvasHome.propTypes = {
  next: PropTypes.string
};

const Canvas = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const currentGroupId = auth.getCurrentGroupId();
  const queryString = location.search;
  return (
    <Switch>
      <Route strict exact path={`${path}/default`}>
        <Redirect
          to={{
            pathname: `default_${currentGroupId}`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/issues`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Technology-ReportedIssues`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/calloverview`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Technology-CallOverview`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/agentoverview`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Technology-AgentOverview`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/comparison`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Technology-CohortComparison`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/logserrors`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Technology-LogsAndErrors`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/technology`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Technology-NetworksApplicationsEquipment`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/coverage`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Technology-CollectionCoverage`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/agentinteractions`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Operations-AgentInteractions`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/customerexperience`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Operations-CustomerExperience`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/conversationanalytics`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Operations-ConversationalAnalytics`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route strict exact path={`${path}/jabra`}>
        <Redirect
          to={{
            pathname: `${currentGroupId}_Jabra`,
            search: `${queryString}`
          }}
        />
      </Route>
      <Route path={`${path}`} component={CanvasHome} />
    </Switch>
  );
};

export default withRouter(Canvas);
