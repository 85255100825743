import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";

class TopLinearProgressBar extends Component {
  render() {
    if (this.props.loading) {
      return <LinearProgress />;
    } else {
      return "";
    }
  }
}

TopLinearProgressBar.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  loading: state.progress.loading
});

TopLinearProgressBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopLinearProgressBar);

export default withStyles({ name: "TopLinearProgressBar" })(
  TopLinearProgressBar
);
