import React, { useState } from "react";
import {
  Modal,
  Stack,
  ButtonRefactored as Button,
  InputText,
} from "@operata/adagio";

interface ModalApiCreateProps {
  onClose: () => void;
  handleCreate: (label: string) => void;
}

export function ModalApiCreate({ onClose, handleCreate }: ModalApiCreateProps) {
  const [label, setLabel] = useState("");

  return (
    <Modal isOpen onClose={onClose} width="micro">
      <Modal.Header border>Create API Key</Modal.Header>
      <Modal.Content>
        <Stack direction="column" gap="medium" padding="medium" block>
          <InputText
            label="API Key Name"
            labelSecondary="Required"
            placeholder="API key name"
            description="This is how you will identify this key in the API table"
            value={label}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setLabel(e.target.value)
            }
          />
        </Stack>
      </Modal.Content>
      <Modal.Footer background>
        <Stack direction="row" gap="tiny" justify="end">
          <Button onClick={onClose} type="tertiary">
            Cancel
          </Button>
          <Button
            onClick={() => handleCreate(label)}
            type="primary"
            disabled={!label.trim()}
          >
            Create secret key
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}
