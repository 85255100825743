import React from "react";
import { MUIDataTable } from "@operata/ui-components";
import moment from "moment";
import _ from "lodash";
import PropTypes from "prop-types";

function getFilterDateRange(data) {
  if (data && data.length) {
    return _.uniqBy(
      data.map(result => {
        return moment(result.createdOn).format("DD-MM-YYYY");
      })
    );
  }
}

function StatusResults(props) {
  const resultColumns = [
    {
      name: "Timestamp",
      label: "Timestamp",
      options: {
        filter: true,
        filterOptions: {
          names: getFilterDateRange(props.data),
          logic(timestamp, filterDate) {
            let formattedFilterDate = moment(filterDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            );
            return !moment(timestamp, "DD-MM-YYYY HH:mm:ss").isBetween(
              formattedFilterDate + " 00:00:00",
              formattedFilterDate + " 23:59:59"
            );
          }
        },
        customBodyRender: timestamp => {
          return moment(timestamp).format("DD-MM-YYYY HH:mm:ss");
        }
      }
    },
    {
      name: "Connect Region",
      label: "Connect Region",
      options: {
        filter: true,
        customFilterListRender: value => `Connect Region: ${value}`,
        sort: true,
        customBodyRender: value => {
          return <span className="textfield">{value}</span>;
        }
      }
    },
    {
      name: "Agent Region",
      label: "Agent Region",
      options: {
        filter: true,
        customFilterListRender: value => `Agent Region: ${value}`,
        sort: true,
        customBodyRender: value => {
          return <span className="textfield">{value}</span>;
        }
      }
    },
    {
      name: "CX Score",
      label: "CX Score",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "AX Score",
      label: "AX Score",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "flags",
      label: "Flags",
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  const resultOptions = {
    filter: false,
    search: false,
    selectableRows: "none",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 50]
  };

  return (
    <div className="statusResultsTable__wrapper">
      <div className="statusResultsTable__header" />
      <div className="statusResultsTable__table">
        <MUIDataTable
          data={props.data.map(result => {
            return [
              result.createdOn,
              result.connectRegion,
              result.agentRegion,
              result.cxScore,
              result.axScore,
              result.tags
            ];
          })}
          columns={resultColumns}
          options={resultOptions}
        />
      </div>
    </div>
  );
}

StatusResults.propTypes = {
  data: PropTypes.array
};

export default StatusResults;
