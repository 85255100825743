import PropTypes from "prop-types";
import React from "react";
import { ScatterPlot } from "@operata/ui-components";
import { useHistory } from "react-router-dom";

const InsightsChart = ({ logs, filters }) => {
  const history = useHistory();

  const chartData = [
    {
      name: "Human Agent CX Score",
      marker: {
        radius: 8,
        symbol: "triangle",
        fillColor: "#3300cc"
      }
    },
    {
      name: "Virtual Agent CX Score",
      marker: {
        radius: 8,
        symbol: "triangle",
        fillColor: "#3300ce"
      }
    },
    {
      name: "Human Agent AX Score",
      marker: {
        radius: 8,
        symbol: "triangle-down",
        fillColor: "#ff33cc"
      }
    },
    {
      name: "Virtual Agent AX Score",
      marker: {
        radius: 8,
        symbol: "triangle-down",
        fillColor: "#d901af"
      }
    },
    {
      name: "Human Agent Network Score",
      marker: {
        radius: 5,
        symbol: "circle",
        fillColor: "#66cccc"
      }
    },
    {
      name: "Virtual Agent Network Score",
      marker: {
        radius: 5,
        symbol: "circle",
        fillColor: "#42c0c0"
      }
    }
  ];

  let virtualLogs = [];
  let humanLogs = [];
  let displayVirtual =
    filters.agentType && filters.agentType.length > 0
      ? filters.agentType.includes("Virtual")
      : true;
  let displayHuman =
    filters.agentType && filters.agentType.length > 0
      ? filters.agentType.includes("Human")
      : true;

  logs.sort(function(a, b) {
    if (a.jobId > b.jobId) {
      return 1;
    }
    return -1;
  });

  logs.forEach(log => {
    if (log.agentType === "VIRTUAL_AGENT") {
      virtualLogs.push(log);
    } else if (log.agentType === "HUMAN_AGENT") {
      humanLogs.push(log);
    }
  });

  return (
    <div className="insights__scatter-chart">
      <ScatterPlot
        exportable
        xAxis={{
          title: {
            text: "Time"
          },
          type: "datetime",
          units: [["minute", [30]], ["hour", [1, 2, 5]], ["day", [1]]]
        }}
        yAxis={{
          title: {
            text: "Score"
          },
          min: 0,
          max: 10,
          tickInterval: 1
        }}
        markerRadius={5}
        tooltip={{
          headerFormat: "<b>{point.key}</b><br>",
          pointFormat: "Agent: {point.agentId} <br/>{series.name}: {point.y}",
          xDateFormat: "%a %d/%m - %I:%M %p"
        }}
        loading={{
          hideDuration: 1000,
          showDuration: 1000
        }}
        onPointClick={({ point }) => {
          history.push(`/gsm/heartbeat/jobs/${point.jobId}/report`);
        }}
        data={[
          {
            data: humanLogs.map(log => {
              return {
                x: new Date(log.createdOn),
                y: log.cxScore,
                jobId: log.jobId,
                agentId: log.agentId
              };
            }),
            name: chartData[0].name,
            marker: {
              radius: chartData[0].marker.radius,
              symbol: chartData[0].marker.symbol,
              fillColor: chartData[0].marker.fillColor
            },
            showInLegend: displayHuman
          },
          {
            data: virtualLogs.map(log => {
              return {
                x: new Date(log.createdOn),
                y: log.cxScore,
                jobId: log.jobId,
                agentId: log.agentId
              };
            }),
            name: chartData[1].name,
            marker: {
              radius: chartData[1].marker.radius,
              symbol: chartData[1].marker.symbol,
              fillColor: chartData[1].marker.fillColor
            },
            showInLegend: displayVirtual
          },
          {
            data: humanLogs.map(log => {
              return {
                x: new Date(log.createdOn),
                y: log.axScore,
                jobId: log.jobId,
                agentId: log.agentId
              };
            }),
            name: chartData[2].name,
            marker: {
              radius: chartData[2].marker.radius,
              symbol: chartData[2].marker.symbol,
              fillColor: chartData[2].marker.fillColor
            },
            showInLegend: displayHuman
          },
          {
            data: virtualLogs.map(log => {
              return {
                x: new Date(log.createdOn),
                y: log.axScore,
                jobId: log.jobId,
                agentId: log.agentId
              };
            }),
            name: chartData[3].name,
            marker: {
              radius: chartData[3].marker.radius,
              symbol: chartData[3].marker.symbol,
              fillColor: chartData[3].marker.fillColor
            },
            showInLegend: displayVirtual
          },
          {
            data: humanLogs.map(log => {
              return {
                x: new Date(log.createdOn),
                y: log.networkScore,
                jobId: log.jobId,
                agentId: log.agentId
              };
            }),
            name: chartData[4].name,
            marker: {
              radius: chartData[4].marker.radius,
              symbol: chartData[4].marker.symbol,
              fillColor: chartData[4].marker.fillColor
            },
            showInLegend: displayHuman
          },
          {
            data: virtualLogs.map(log => {
              return {
                x: new Date(log.createdOn),
                y: log.networkScore,
                jobId: log.jobId,
                agentId: log.agentId
              };
            }),
            name: chartData[5].name,
            marker: {
              radius: chartData[5].marker.radius,
              symbol: chartData[5].marker.symbol,
              fillColor: chartData[5].marker.fillColor
            },
            showInLegend: displayVirtual
          }
        ]}
      />
    </div>
  );
};

export default InsightsChart;

InsightsChart.propTypes = {
  logs: PropTypes.array
};
