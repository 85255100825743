import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showConfirmationDialog } from "../../actions/dialog";
import "./Configs.scss";
import { formatDate } from "../../utils/date";
import {
  Stack,
  Table,
  ButtonRefactored as Button,
  DeleteIcon,
  EmptyState,
  LockIcon,
  Section,
  HeadingDetailed,
  AddIcon
} from "@operata/adagio";
import Portal from "../Portal/Portal";
import { ModalApiRevoke } from "./Modal/ModalApiRevoke";
import { ModalApiCreate } from "./Modal/ModalApiCreate";
import ModalViewApi from "./Modal/ModalViewApi";
import uuidAPIKey from "uuid-apikey";

export const formatString = str => {
  if (str.length <= 7) {
    return str;
  }
  const firstPart = str.slice(0, 2);
  const lastPart = str.slice(-5);
  return `${firstPart}...${lastPart}`;
};

/**
 * Component for managing API tokens.
 * Displays a list of API tokens with options to create new ones and revoke existing ones.
 * Also handles modals for token creation, saving, and revocation.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Array<Object>} [props.values=[]] - Array of API token objects
 * @param {Function} props.onRevoke - Callback when a token is revoked
 * @param {Function} props.onCreate - Callback when a new token is created
 * @param {boolean} props.editable - Whether the tokens can be modified (created/revoked)
 * @returns {JSX.Element} API token management interface
 */

const ApiTokenConfig = ({ values = [], onRevoke, onCreate, editable }) => {
  const [selectedToken, setSelectedToken] = useState(null);
  const [isModalRevoke, setModalRevoke] = useState(false);
  const [isModalCreate, setModalCreate] = useState(false);
  const [isModalSave, setModalSave] = useState(false);
  const [newToken, setNewToken] = useState(null);

  const handleOpenRevoke = token => {
    setSelectedToken(token);
    setModalRevoke(true);
  };

  const handleCloseRevoke = () => {
    setModalRevoke(false);
    setSelectedToken(null);
  };

  const handleOpenCreate = () => {
    setModalCreate(true);
  };

  const handleCreateModal = label => {
    const token = {
      label,
      token: uuidAPIKey.create({ noDashes: true }).apiKey,
      createdOn: new Date().toISOString()
    };
    console.log("handleCreateModal called with label:", label);
    setNewToken(token);
    onCreate(token);
    setModalCreate(false);
    setModalSave(true);
  };

  return (
    <>
      <Stack direction="column" padding="medium" gap="medium">
        {editable && (
          <HeadingDetailed
            level={1}
            title={"API Management"}
            description={
              "Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, Operata may also automatically disable any API key that has leaked publicly."
            }
            actions={
              <Button
                type="primary"
                size="large"
                icon={<AddIcon />}
                onClick={handleOpenCreate}
              >
                Create New Key
              </Button>
            }
          />
        )}

        <Section>
          <Stack direction="column" padding="medium" gap="medium" block>
            {values.length > 0 ? (
              <>
                <Table>
                  <thead>
                    <Table.Row>
                      <Table.Header>Name</Table.Header>
                      <Table.Header>Token</Table.Header>
                      <Table.Header>Last used</Table.Header>
                      <Table.Header>Created on</Table.Header>
                      <Table.Header width={0} />
                    </Table.Row>
                  </thead>
                  <tbody>
                    {values.map((value, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <Table.Text color="primary">{value.label}</Table.Text>
                        </Table.Cell>
                        <Table.Cell>
                          <Table.Text color="secondary">
                            {value.token && formatString(value.token)}
                          </Table.Text>
                        </Table.Cell>
                        <Table.Cell>
                          <Table.Text color="secondary">
                            {value.lastAccessed
                              ? formatDate(value.lastAccessed)
                              : "Never"}
                          </Table.Text>
                        </Table.Cell>
                        <Table.Cell>
                          <Table.Text color="secondary">
                            {value.createdOn ? formatDate(value.createdOn) : ""}
                          </Table.Text>
                        </Table.Cell>
                        {editable && (
                          <Table.Cell padding={"medium"}>
                            <Stack direction="row" gap="micro">
                              <Button
                                type="ghost"
                                size="medium"
                                icon={<DeleteIcon />}
                                tone="danger"
                                onClick={() => handleOpenRevoke(value)}
                                tooltip={{
                                  label: "Revoke Key",
                                  position: "bottom"
                                }}
                              />
                            </Stack>
                          </Table.Cell>
                        )}
                      </Table.Row>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <EmptyState
                icon={<LockIcon />}
                title={"Create an API key to access the Operata API"}
              />
            )}
          </Stack>
        </Section>

        {isModalRevoke && (
          <Portal>
            <ModalApiRevoke
              onClose={handleCloseRevoke}
              handleRevoke={() => {
                onRevoke(selectedToken);
                handleCloseRevoke();
              }}
              token={selectedToken}
            />
          </Portal>
        )}

        {isModalCreate && (
          <Portal>
            <ModalApiCreate
              onClose={() => setModalCreate(false)}
              handleCreate={handleCreateModal}
            />
          </Portal>
        )}

        {isModalSave && (
          <Portal>
            <ModalViewApi
              onClose={() => setModalSave(false)}
              token={newToken?.token}
            />
          </Portal>
        )}
      </Stack>
    </>
  );
};

ApiTokenConfig.propTypes = {
  /** Array of API token objects */
  values: PropTypes.array,
  /** Callback function when a token is revoked */
  onRevoke: PropTypes.func,
  /** Callback function when a new token is created */
  onCreate: PropTypes.func,
  /** Callback for showing confirmation dialogs */
  showConfirmationDialog: PropTypes.func,
  /** Whether tokens can be created or revoked */
  editable: PropTypes.bool
};

const mapDispatchToProps = {
  showConfirmationDialog
};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiTokenConfig);
