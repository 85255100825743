import { Badge, Popover, Sidebar } from "@operata/adagio";
import React from "react";
import { auth } from "../../auth/Auth";
import { getDashboardSlug } from "../../utils/dashboardMap";

type PlaybookOption = {
   label: string;
   value: string;
   alias?: string;
   url: string;
   analytics: string;
   badge?: JSX.Element;
   new?: boolean;
};

type PlaybookGroup = {
   groupTitle: string;
   items: PlaybookOption[];
};

type PlaybookOptions = (PlaybookOption | PlaybookGroup)[];

const isPlaybookGroup = (option: PlaybookOption | PlaybookGroup): option is PlaybookGroup => {
   return (option as PlaybookGroup).items !== undefined;
};

const playbookOptions: PlaybookOptions = [
   {
      label: "Overview",
      value: "default",
      alias: "Playbook #1",
      url: `default`,
      analytics: "canvas-overview"
   },
   {
      groupTitle: "Technology",
      items: [
         {
            label: "Networks, Applications and Equipment",
            value: "technology",
            alias: "Playbook #3",
            url: `technology`,
            badge: <Badge tone="identifier">Optimize</Badge>,
            analytics: "canvas-networks-applications-and-equipment"
         },
         {
            label: "Reported Issues and Insights",
            value: "issues",
            alias: "Playbook #7",
            url: `issues`,
            badge: <Badge tone="identifier">Investigate</Badge>,
            analytics: "canvas-reported-issues-and-insights"
         },
         {
            label: "Call Overview",
            value: "calloverview",
            alias: "Playbook #7",
            url: `calloverview`,
            badge: <Badge tone="identifier">Investigate</Badge>,
            analytics: "canvas-call-overview"
         },
         {
            label: "Agent Overview",
            value: "agentoverview",
            alias: "Playbook #4",
            url: `agentoverview`,
            badge: <Badge tone="identifier">Investigate</Badge>,
            analytics: "canvas-agent-overview"
         },
         {
            label: "Cohort Comparison",
            value: "comparison",
            alias: "Playbook #6",
            url: `comparison`,
            badge: <Badge tone="identifier">Investigate</Badge>,
            analytics: "canvas-cohort-comparison"
         },
         {
            label: "Logs and Errors",
            value: "logserrors",
            alias: "Playbook #5",
            url: `logserrors`,
            badge: <Badge tone="identifier">Investigate</Badge>,
            analytics: "canvas-logs-and-errors"
         },
         {
            label: "Collection Coverage",
            value: "coverage",
            url: `coverage`,
            badge: <Badge tone="identifier">Admin</Badge>,
            new: true,
            analytics: "canvas-collection-coverage"
         }
      ]
   },
   {
      groupTitle: "Operations",
      items: [
         {
            label: "Agent Interactions",
            value: "agentinteractions",
            alias: "Playbook #2",
            url: `agentinteractions`,
            badge: <Badge tone="identifier">Optimize</Badge>,
            analytics: "canvas-agent-interactions"
         },
         {
            label: "Customer Experience",
            value: "customerexperience",
            alias: "Playbook #8",
            url: `customerexperience`,
            badge: <Badge tone="identifier">Optimize</Badge>,
            analytics: "canvas-customer-experience"
         },
         {
            label: "Conversational Analytics",
            value: "conversationanalytics",
            alias: "Playbook #9",
            url: `conversationanalytics`,
            badge: <Badge tone="identifier">Optimize</Badge>,
            analytics: "canvas-conversational-analytics"
         }
      ]
   },
   {
      label: "All Dashboards",
      value: "list",
      url: `list`,
      analytics: "canvas-all-dashboards"
   }
];

const enhanceOptionsWithSelectedState = (options: PlaybookOptions, location: Location, authCurrentGroupId: string) => {
   const lastPathSegment = location.pathname.split("/").pop();

   return options.map(option => {
      if (isPlaybookGroup(option)) {
         return {
            ...option,
            items: option.items.map(item => ({
               ...item,
               url: `/${authCurrentGroupId}/canvas/${item.url}`,
               selected: lastPathSegment === getDashboardSlug(item.value, authCurrentGroupId)
            }))
         };
      }
      return {
         ...option,
         url: `/${authCurrentGroupId}/canvas/${option.url}`,
         selected: lastPathSegment === getDashboardSlug(option.value, authCurrentGroupId)
      };
   });
};

const CanvasMenu: React.FC<{ sidebarExpanded: boolean; isSelected: boolean; location: Location }> = ({ sidebarExpanded, isSelected, location }) => {
   const authCurrentGroupId = auth.getCurrentGroupId();
   const enhancedOptions = enhanceOptionsWithSelectedState(playbookOptions, location, authCurrentGroupId);

   return (
      <Popover
         anchor="sidebar"
         analytics="canvas-menu"
         trigger={
            <Sidebar.MenuItem
               submenu
               isExpanded={sidebarExpanded}
               icon={<Sidebar.PlaybooksIcon />}
               name="Canvas"
               isSelected={isSelected}
            />
         }
      >
         {/*used by popover component*/}
            <>
            {enhancedOptions.map(option => (
               <Popover.Section title={"groupTitle" in option ? option.groupTitle : undefined} key={"groupTitle" in option ? option.groupTitle : option.value}>
                  {"items" in option ? (
                     option.items.map(item => (
                        <Popover.Menuitem
                           key={item.value}
                           href={item.url}
                           badge={item.badge}
                           analytics={item.analytics}
                           selected={item.selected}
                        >
                           {item.label}
                        </Popover.Menuitem>
                     ))
                  ) : (
                     <Popover.Menuitem
                        key={option.value}
                        href={option.url}
                        analytics={option.analytics}
                        selected={option.selected}
                     >
                        {option.label}
                     </Popover.Menuitem>
                  )}
               </Popover.Section>
            ))}
         </>
      </Popover>
   );
};

export default CanvasMenu;
