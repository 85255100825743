export const BREADCRUMB_CHANGE_PATH = "BREADCRUMB_CHANGE_PATH";
export const BREADCRUMB_UPDATE_DYNAMIC = "BREADCRUMB_UPDATE_DYNAMIC";

export const changePath = path => ({
  type: BREADCRUMB_CHANGE_PATH,
  path: path
});

export const updateDynamicBreadcrumb = data => ({
  type: BREADCRUMB_UPDATE_DYNAMIC,
  pathData: data
});
