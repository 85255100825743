import React, { useState } from "react";
import {
  Modal,
  Stack,
  ButtonRefactored as Button,
  InputText,
  Typography,
  CopyIcon as IconCopy,
} from "@operata/adagio";

interface ModalViewApiProps {
  onClose: () => void;
  token: string;
}

function ModalViewApi({ onClose, token }: ModalViewApiProps) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(token);
      setCopied(true);
      // Reset the copied state after 2 seconds
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  return (
    <Modal isOpen onClose={onClose} width="micro" undismissable>
      <Modal.Header border>Save your Key</Modal.Header>
      <Modal.Content>
        <Stack direction="column" gap="medium" padding="medium" block>
          <Typography>
            <p>
              Please save this secret key somewhere safe and accessible. For
              security reasons, <b>you won&apos;t be able to view it again</b>{" "}
              through your Operata account. If you lose this secret key,
              you&apos;ll need to generate a new one.
            </p>
          </Typography>
          <InputText
            label="Secret Key"
            value={token}
            monospace //size="large"
            action={
              <Button
                size="medium"
                type="primary"
                icon={<IconCopy />}
                onClick={handleCopy}
              >
                {copied ? "Copied!" : "Copy"}
              </Button>
            }
            message="This key will never be visible again."
          />
        </Stack>
      </Modal.Content>
      <Modal.Footer background>
        <Stack direction="row" gap="tiny" justify="end">
          <Button onClick={onClose} type="tertiary" size="medium">
            Done
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalViewApi;
