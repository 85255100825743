import React, { useState, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import MUIDataTable from "../Datatables/MUIDataTable";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { CSVLink } from "react-csv";

import { downloadCsv } from "../../actions/extensionCoverage";
import { TableCell } from "@material-ui/core";

const ExtensionCoverage = () => {
  const [coveragePeriod, setCoveragePeriod] = useState("now-1d");
  const dispatch = useDispatch();

  const { tableRows } = useSelector(state => state.extensionCoverage);

  useEffect(
    () => {
      dispatch(downloadCsv(coveragePeriod));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coveragePeriod]
  );

  const handleSelectUtilisationPeriod = (event, newPeriod) => {
    if (newPeriod != null) {
      setCoveragePeriod(newPeriod);
    }
  };

  const columns = [
    {
      name: "description",
      label: "DESCRIPTION",
      options: {
        customBodyRender: item => {
          return (
            <React.Fragment>
              <div style={{ fontWeight: "bold" }}>{item.heading}</div>
              <div>{item.description}</div>
            </React.Fragment>
          );
        }
      }
    },
    {
      name: "agents",
      label: "AGENTS",
      options: {
        customHeadRender: column => {
          return (
            <TableCell style={{ textAlign: "right" }}>{column.label}</TableCell>
          );
        },
        customBodyRender: item => {
          return (
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center"
                }}
              >
                <span>{item}</span>
                <span style={{ paddingLeft: "8px", paddingTop: "4px" }}>
                  <PeopleAltIcon />
                </span>
              </div>
            </React.Fragment>
          );
        }
      }
    },
    {
      name: "data",
      label: "ACTION",
      options: {
        customHeadRender: column => {
          return (
            <TableCell style={{ textAlign: "right" }}>{column.label}</TableCell>
          );
        },
        customBodyRender: data => {
          const csvLink = React.createRef();
          return (
            <React.Fragment>
              <CSVLink
                data={data.csv}
                filename={data.filename}
                className="hidden"
                ref={csvLink}
              />
              <button
                className={"btnColoured-no-padding"}
                onClick={() => {
                  csvLink.current.link.click();
                }}
                style={{
                  padding: "none"
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <span style={{ paddingRight: "8px", paddingTop: "4px" }}>
                    <DownloadIcon />
                  </span>
                  <span>Download CSV</span>
                </div>
              </button>
            </React.Fragment>
          );
        },
        setCellProps: () => ({ style: { textAlign: "right" } })
      }
    }
  ];

  const options = {
    selectableRows: "none",
    filter: false,
    fixedHeader: false,
    viewColumns: false,
    pagination: false,
    search: false,
    download: false,
    sort: false,
    print: false
  };

  return (
    <React.Fragment>
      <div style={{ textAlign: "right", zIndex: 10, position: "relative" }}>
        <ToggleButtonGroup
          size="large"
          color="primary"
          value={coveragePeriod}
          exclusive="true"
          onChange={handleSelectUtilisationPeriod}
        >
          <ToggleButton
            style={{ width: "45px", height: "32px" }}
            value="now-1d"
          >
            24h
          </ToggleButton>
          <ToggleButton
            style={{ width: "45px", height: "32px" }}
            value="now-7d"
          >
            7d
          </ToggleButton>
          <ToggleButton
            style={{ width: "45px", height: "32px" }}
            value="now-28d"
          >
            28d
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div style={{ marginTop: "0px" }}>
        <MUIDataTable data={tableRows} columns={columns} options={options} />
      </div>
    </React.Fragment>
  );
};

export default ExtensionCoverage;
