import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  Skeleton,
  ButtonRefactored as Button, HistoryIcon, PageFilter, Chip, FilterIcon, InputSelect, InputText
} from "@operata/adagio";
import DateTimePicker from "../DateTime/Wrapper";
import { getRanges } from "../DateTime/lib/utils/TimeFunctionUtils";
import TablePagination from "../Billing/TablePagination";
import { fetchCallStats } from "../../actions/callstats";
import moment from "moment-timezone";
import { connect, useDispatch } from "react-redux";
import ModalWrapper from "../CallOnAPage/ModalWrapper";
import CallOnPageModal from "../CallOnAPage/CallOnAModal";
import { useHistory, useRouteMatch } from "react-router-dom";
import { createCSVDownload } from "../Datatables/utils";
import {RouteComponentProps, withRouter} from "react-router-dom";
import { CallSummary } from "../../models/callstats";
import { Filter, FilterByField, SearchCriteria, RangeKey } from "../../models/dataTable";
import { RootState } from "../../store/store";

export const getSearchCriteria = (
  timeRange: string[],
  filters: Filter[],
  page: number,
  pageSize: number,
  sortKey?: string,
  sortOrder?: string
): SearchCriteria => {
  timeRange = timeRange || ["Last 4 hours", "now-4h", "now"];
  const [, fromTime, toTime] = timeRange;

  const criteria: SearchCriteria = {
    fromTime,
    toTime,
    timezone: moment.tz.guess(),
    size: pageSize,
    from: page * pageSize
  };

  // this is to get around the fact that the backend expects a range for numeric fields
  const maxValue = 999999;
  const minValue = 0;

  filters.forEach(({ field, operator, value }) => {
    const numericFields = ['jitter', 'mos', 'rtt', 'packetsLostFraction', 'durationSeconds'];

    if (numericFields.includes(field)) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        if (operator === '>=') {
          (criteria as any)[`${field}From`] = numericValue;
          (criteria as any)[`${field}To`] = maxValue;
        } else if (operator === '<=') {
          (criteria as any)[`${field}From`] = minValue;
          (criteria as any)[`${field}To`] = numericValue;
        } else if (operator === '=') {
          (criteria as any)[`${field}From`] = numericValue;
          (criteria as any)[`${field}To`] = numericValue;
        }
      }
    } else {
      if (!(field in criteria)) {
        (criteria as any)[field] = value;
      }
    }
  });

  if (sortKey && sortOrder) {
    (criteria as any)["sortColumn"] = sortKey;
    (criteria as any)["sortDirection"] = sortOrder;
  }

  return criteria;
};

interface CallStatsLogProps extends RouteComponentProps {
  fetchCallStats: (criteria: SearchCriteria) => void;
  calls: CallSummary[];
  recordCount: number;
  loading: boolean;
  path: string;
}

const CallStatsLog = ({ calls, recordCount, loading, path }: CallStatsLogProps) => {
  const fields = ["agent", "contactId", "agentISP", "mos", "jitter", "rtt", "packetsLostFraction", "durationSeconds"];
  const operators = ["=", ">=", "<="];

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [timeRange, setTimeRange] = useState<string[]>(["Last 4 hours", "now-4h", "now"]);
  const [contactId, setContactId] = useState<string>("");
  const [sortKey, setSortKey] = useState("timestamp");
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedField, setSelectedField] = useState<string>(fields[0]);
  const [selectedOperator, setSelectedOperator] = useState<string>(operators[0]);
  const [value, setValue] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<Filter[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number>(0);
  const [selectedRange, setSelectedRange] = useState("Last 4 hours");

  const isSaveDisabled = !selectedField || !selectedOperator || !value;

  const handleEditFilter = (index: number) => {
    const filterToEdit = activeFilters[index];
    setSelectedField(filterToEdit.field);
    setSelectedOperator(filterToEdit.operator);
    setValue(filterToEdit.value);
    setIsFilterOpen(true);
    setIsEditing(true);
    setEditingIndex(index);
  };

  function resetFilters() {
    setActiveFilters([]);
    setPage(0);
    setSelectedRange("Last 4 hours");
    setTimeRange(["Last 4 hours", "now-4h", "now"]);
  }

  const handleNewFilterClick = () => {
    if (isFilterOpen){
      setIsFilterOpen(false);
      return;
    }
    resetForm();
    setIsFilterOpen(true);
  };

  const resetForm = () => {
    setSelectedField(fields[0]);
    setSelectedOperator(operators[0]);
    setValue('');
    setIsFilterOpen(false);
    setIsEditing(false);
    setEditingIndex(0);
  };

  const handleAddFilter = () => {
    const newFilter = {
      field: selectedField,
      operator: selectedOperator,
      value: value,
    };

    if (isEditing && editingIndex !== null) {
      const updatedFilters = [...activeFilters];
      updatedFilters[editingIndex] = newFilter;
      setActiveFilters(updatedFilters);
    } else {
      setActiveFilters([...activeFilters, newFilter]);
    }

    setPage(0);
    resetForm();
  };

  const downloadCSV = () => {
    const columns = [
      { name: "Timestamp", download: true },
      { name: "Agent", download: true },
      { name: "MOS", download: true },
      { name: "Contact ID", download: true },
      { name: "Agent ISP", download: true },
      { name: "Jitter", download: true },
      { name: "RTT", download: true },
      { name: "Packet Loss", download: true },
      { name: "Duration", download: true }
    ];

    const data = calls.map(call => ({
      data: [
        call.timestamp,
        call.agent,
        call.mos.avg.toFixed(2),
        call.callContactId,
        call.agentISP,
        call.jitter.avg,
        call.rtt.avg,
        call.totals.packetsLostFraction,
        call.durationSeconds
      ]
    }));

    const options = {
      downloadOptions: {
        filename: "tableDownload.csv",
        separator: ","
      }
    };

    createCSVDownload(columns, data, options);
  };

  const handleSortClick = (key: string) => {
    if (sortKey === key) {
      setSortOrder(prevOrder => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortKey(key);
      setSortOrder("desc");
    }
  };

  const handleDeleteFilter = (indexToDelete: number, event: any) => {
    event.stopPropagation();
    setActiveFilters(activeFilters.filter((_, index) => index !== indexToDelete));
  };

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(
    () => {
      dispatch(
        fetchCallStats(
          getSearchCriteria(
            timeRange,
            activeFilters,
            page,
            pageSize,
            sortKey,
            sortOrder
          )
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      timeRange,
      page,
      pageSize,
      activeFilters,
      sortKey,
      sortOrder
    ]
  );

  const handleButtonClick = (id: string) => {
    setContactId(id);
    history.push(`${path}/call/${id}`);
  };

  useEffect(() => {
    if (pickerOpen){
      setIsFilterOpen(false);
    }

  }, [pickerOpen]);

  return (
    <>
      <>
        <PageFilter
          onNewFilterButtonClick={handleNewFilterClick}
          primary={
            <>
              <div
                style={{
                  position: "absolute",
                  marginTop: "5px",
                }}
              >
                <DateTimePicker
                  rightAlign={false}
                  dateRangeSelected={(range: RangeKey, start: string, end: string) => {
                    if (range === "Custom Range") {
                      setTimeRange([range, start, end]);
                    } else {
                      const timeRange = getRanges(new Date())[range];
                      setTimeRange([String(range), String(timeRange[4]), String(timeRange[5])]);
                    }
                    setSelectedRange(range); // Update the selected range state
                    setPickerOpen(false);
                  }}
                  pickerOpen={pickerOpen ? "open" : ""}
                  setPickerOpen={(pickerOpen: boolean) => setPickerOpen(!!pickerOpen)}
                  currentRange={null}
                  currentStartTime={timeRange?.[1]}
                  currentEndTime={timeRange?.[2]}
                  selectedRange={selectedRange}
                />
              </div>
              <Button
                type="tertiary"
                icon={<HistoryIcon/>}
                onClick={setPickerOpen.bind(null, !pickerOpen)}
              >
                {selectedRange}
              </Button>
            </>
          }
          filters={
            <>
              <PageFilter.FilterCreate
                isOpen={isFilterOpen}
                selectedField={selectedField}
                setSelectedField={setSelectedField}
                selectedOperator={selectedOperator}
                setSelectedOperator={setSelectedOperator}
                value={value}
                onCancel={() => setIsFilterOpen(false)}
                setValue={setValue}
                onApply={handleAddFilter}
                operators={["="]}
                isSaveDisabled={isSaveDisabled}
                isEditing={isEditing}
                onNewFilterButtonClick={handleNewFilterClick}
                trigger={
                  <Button
                    type="tertiary"
                    size="medium"
                    icon={<FilterIcon />}
                  >
                    Filters
                  </Button>
                }
                inputSelectField={
                  <InputSelect
                    label="Field"
                    selectedValue={selectedField}
                    setSelectedValue={setSelectedField}
                  >
                    {fields.map((field) => (
                      <InputSelect.Option key={field}>{field}</InputSelect.Option>
                    ))}
                  </InputSelect>
                }
                inputSelectOperator={
                  <InputSelect
                    width={120}
                    label="Operator"
                    selectedValue={selectedOperator}
                    setSelectedValue={setSelectedOperator}
                  >
                    {operators.map((operator) => (
                      <InputSelect.Option key={operator}>{operator}</InputSelect.Option>
                    ))}
                  </InputSelect>
                }
                inputTextValue={
                  <InputText
                    label="Value"
                    value={value}
                    onChange={(e: any) => setValue(e.target.value)}
                  />
                }
              />
              <>
                {activeFilters.map((filter, index) => (
                  <Chip
                    key={index}
                    onClick={() => handleEditFilter(index)}
                    onDelete={(event: any) => handleDeleteFilter(index, event)}
                  >
                    {`${filter.field} ${filter.operator} ${filter.value}`}
                  </Chip>
                ))}
              </>
              <Button
                size="medium"
                type="secondary"
                disabled={activeFilters.length === 0}
                onClick={() => {
                  resetFilters();
                }}
              >
                Clear all
              </Button>
            </>
          }
          actions={
            <Button
              type="secondary"
              size="medium"
              disabled={!calls.length}
              iconAfter={<Table.DownloadIcon />}
              onClick={() => downloadCSV()}
            >
              Download CSV
            </Button>
          }
        />

        <Table>
          <thead>
            <Table.Row>
              <Table.Header
                width={0}
                align="left"
                sortable
                sortIcon={
                  sortKey === "timestamp" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("timestamp")}
              >
                Timestamp
              </Table.Header>
              <Table.Header
                width={120}
                minWidth={120}
                sortable
                sortIcon={
                  sortKey === "agent" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("agent")}
              >
                Agent
              </Table.Header>
              <Table.Header minWidth={200}>Contact ID</Table.Header>
              <Table.Header
                width={120}
                sortable
                sortIcon={
                  sortKey === "agentISP" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("agentISP")}
              >
                Agent ISP
              </Table.Header>
              <Table.Header
                width="10%"
                minWidth={50}
                align="right"
                sortable
                sortIcon={
                  sortKey === "mos.avg" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("mos")}
              >
                MOS
              </Table.Header>
              <Table.Header
                width="10%"
                minWidth={50}
                align="right"
                sortable
                sortIcon={
                  sortKey === "jitter.avg" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("jitter")}
              >
                Jitter (ms)
              </Table.Header>
              <Table.Header
                width="10%"
                minWidth={50}
                align="right"
                sortable
                sortIcon={
                  sortKey === "rtt.avg" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("rtt")}
              >
                RTT (ms)
              </Table.Header>
              <Table.Header
                width="10%"
                minWidth={50}
                align="right"
                sortable
                sortIcon={
                  sortKey === "totals.packetsLostFraction" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() =>
                  handleSortClick("packetsLostFraction")
                }
              >
                Packet Loss (%)
              </Table.Header>
              <Table.Header
                width="10%"
                minWidth={50}
                align="right"
                sortable
                sortIcon={
                  sortKey === "durationSeconds" ? (
                    sortOrder === "desc" ? (
                      <Table.SortDescendingIcon fontSize="inherit" />
                    ) : (
                      <Table.SortAscendingIcon fontSize="inherit" />
                    )
                  ) : null
                }
                onSortClick={() => handleSortClick("durationSeconds")}
              >
                Duration (s)
              </Table.Header>
              <Table.Header width={0} sticky />
            </Table.Row>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: pageSize }, (_, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={60} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={60} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={60} height={16} />
                  </Table.Cell>
                  <Table.Cell align="center">
                    <Skeleton loading width={60} height={16} />
                  </Table.Cell>
                  <Table.Cell sticky>
                    <Skeleton loading width={120} height={16} />
                  </Table.Cell>
                </Table.Row>
              ))
            ) : calls && calls.length > 0 ? (
              calls.map((call, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Table.Text color="tertiary">
                      <div>
                        {call.timestamp ? (
                          moment(call.timestamp).format("DD-MM-YYYY")
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                      <div>
                        {call.timestamp ? (
                          moment(call.timestamp).format("HH:mm:ss")
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                    </Table.Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Table.Text color="primary">
                      {call.agent}
                    </Table.Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Table.Text color="tertiary">
                      {call.callContactId}
                    </Table.Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Table.Text color="tertiary">
                      {call.agentISP}
                    </Table.Text>
                  </Table.Cell>
                  <Table.Cell align="right">
                    <Table.Text color="tertiary">
                      {call.mos.avg.toFixed(2)}
                    </Table.Text>
                  </Table.Cell>
                  <Table.Cell align="right">
                    <Table.Text color="tertiary">
                      {call.jitter.avg}
                    </Table.Text>
                  </Table.Cell>
                  <Table.Cell align="right">
                    <Table.Text color="tertiary">
                      {call.rtt.avg}
                    </Table.Text>
                  </Table.Cell>
                  <Table.Cell align="right">
                    <Table.Text color="tertiary">
                      {call.totals.packetsLostFraction}
                    </Table.Text>
                  </Table.Cell>
                  <Table.Cell align="right">
                    <Table.Text color="tertiary">
                      {call.durationSeconds}
                    </Table.Text>
                  </Table.Cell>
                  <Table.Cell sticky>
                    <Button
                      type="tertiary"
                      size="small"
                      onClick={() => handleButtonClick(call.callContactId)} // Pass the appropriate contactId here
                    >
                      Details
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              // it will complain about using the wrong colspan here but it is correct
              <Table.RowEmpty colspan={9}>No data Found</Table.RowEmpty>
            )}
          </tbody>
        </Table>
        <TablePagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          count={recordCount || 0}
        />
      </>
      <>
        {contactId && (
          <ModalWrapper onClose={() => setContactId("")}>
            <CallOnPageModal
              {...calls}
              history={history}
              match={match}
              path={`${path}/call/${contactId}`}
              contactId={contactId}
              onClose={() => setContactId("")}
            />
          </ModalWrapper>
        )}
      </>
    </>
  );
};

const mapDispatchToProps = {
  fetchCallStats
};

const mapStateToProps = (state: RootState) => ({
  calls: state.callstats.calls || [],
  recordCount: state.callstats.callsRecCount,
  loading: state.callstats.loading
});

const connectedCallStatsLogs = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallStatsLog);

export default withRouter(connectedCallStatsLogs);
