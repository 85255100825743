import React, {useEffect, useState} from "react";
import {
  Redirect,
  Route, RouteComponentProps,
  Switch, useLocation,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import {
  PageHeading,
  Section,
  Sticky,
  SecondarySidebar,
  Stack,
  Badge,
  Main as Layout,
  ButtonRefactored as Button,
  Popover, Heading
} from "@operata/adagio";
import "./style.scss";
import { connect } from "react-redux";
import {
  createRole, DATA_ROLE_USER_ADDED, DATA_ROLE_USER_REMOVED,
  ERROR_ROLE_CREATED,
  fetchPlaybooks,
  fetchRoles, fetchUserRoles,
  ROLE_CREATED, ROLE_UPDATED
} from "../../actions/playbooks";
import { fetchUsers } from "../../actions/users";
import Roles from "./Roles";
import EmbeddedPlaybook from "./EmbeddedPlaybook";
import { withFeatureFlagsConsumer } from "../../FeatureFlags";
import RoleSelector from "./RoleSelector";
import { CreateDataRole, DataRole, EmbeddedDashboard } from "../../models/dataRole";
import { LDFlagSet } from "launchdarkly-react-client-sdk";
import { RootState } from "../../store/store";
import { UserProfile } from "../../models/user";
import { showSnackbarMessage, SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../actions/snackbar";
import { auth } from "../../auth/Auth";
import PlaybookSidebar from "./PlaybooksSidebar";

interface PlaybooksProps extends RouteComponentProps {
  fetchRoles: () => void,
  fetchUserRoles: () => void,
  fetchUsers: () => void,
  fetchPlaybooks: () => void,
  createRole: (role: CreateDataRole) => void,
  showSnackbarMessage: (messageType: string, message: string) => void,
  users: UserProfile[],
  roles: DataRole[],
  userRoles: DataRole[],
  usersForRole: Record<number, string[]>,
  playbooks: EmbeddedDashboard[],
  playbookStatus?: string,
  flags: LDFlagSet

}

const Playbooks = ({ fetchRoles,
                     fetchUserRoles,
                     fetchUsers,
                     fetchPlaybooks,
                     createRole,
                     showSnackbarMessage,
                     users,
                     roles,
                     userRoles,
                     usersForRole,
                     playbooks,
                     playbookStatus,
                     flags }: PlaybooksProps) => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const [availableRoles, setAvailableRoles] = useState<DataRole[]>([]);
  const [currentRole, setCurrentRole] = useState<DataRole | undefined>(undefined);
  const [currentPlaybook, setCurrentPlaybook] = useState<EmbeddedDashboard | undefined>(undefined);

  useEffect(() => {
    if (userRoles.length === 0 && currentRole === undefined && availableRoles.length === 0) {
      return;
    } else if (userRoles.length === 0) {
      setCurrentRole(undefined);
      setAvailableRoles([]);
      return;
    }

    setAvailableRoles(userRoles);

    if (currentRole === undefined && userRoles?.length > 0) {
      setCurrentRole(userRoles[0]);
    }
  }, [currentRole, userRoles, roles]);

  useEffect(
    () => {
      fetchRoles();
      fetchUserRoles();
      fetchUsers();
      fetchPlaybooks();
    },
    [fetchPlaybooks, fetchRoles, fetchUserRoles, fetchUsers]
  );

  useEffect(() => {
    if (playbookStatus === ROLE_CREATED) {
      showSnackbarMessage(SNACKBAR_SUCCESS, "Role created successfully");
      fetchRoles();
    } else if (playbookStatus === ROLE_UPDATED) {
      showSnackbarMessage(SNACKBAR_SUCCESS, "Role updated successfully");
      fetchRoles();
    } else if (playbookStatus === DATA_ROLE_USER_ADDED || playbookStatus === DATA_ROLE_USER_REMOVED) {
      fetchUserRoles();
    } else if (playbookStatus === ERROR_ROLE_CREATED) {
      showSnackbarMessage(SNACKBAR_ERROR, "Error creating role");
    }
  }, [playbookStatus, fetchRoles, showSnackbarMessage]);

  useEffect(() => {
    const playbookId = location.pathname.split("/").pop();
    const foundPlaybook = playbooks.find(p => p.id === parseInt(playbookId || "", 10));
    setCurrentPlaybook(foundPlaybook);
  }, [location.pathname, playbooks]);

  const handleCreateRole = (role: CreateDataRole) => {
    createRole(role);
  };

  if (flags.embeddedCanvasApi === null) {
    return <></>;
  } else if (flags.embeddedCanvasApi === false) {
    return <Redirect to={"/"} />;
  }

  const groupName = auth.getCurrentGroupName();

  return (
    <div id={"playbooks"}>
      <Layout.Container sidebar="true">
        <Layout.Header>
          <Stack direction="row" align="center" gap="medium">
            {/*TODO: replace div with real adagio component to confine space*/}
            <div className="data-team">
              <RoleSelector activeRoleId={currentRole?.id || 0} roles={availableRoles} updateRole={(roleId) => setCurrentRole(roles.find(r => r.id === roleId))}></RoleSelector>
            </div>
            {currentPlaybook && <Heading level={2}>{currentPlaybook.name}</Heading>}
          </Stack>
        </Layout.Header>
        <Layout.Sidebar>
          <PlaybookSidebar playbooks={playbooks}/>
        </Layout.Sidebar>
        <Layout.Scroll>
          <Switch>
            <Route exact path={path}>
            </Route>
            <Route exact path={`${path}/roles`}>
              <Stack padding={"medium"} gap={"12"}>
                <PageHeading title={groupName}/>
                <Section>
                  <Stack padding={"medium"} block justify={"space-between"}>
                    <Roles roles={roles} users={users} usersForRole={usersForRole}
                           onCreate={handleCreateRole}
                    />
                  </Stack>
                </Section>
              </Stack>
            </Route>
            <Route path={`${path}/:id`}>
              <EmbeddedPlaybook playbooks={playbooks} role={currentRole}/>
            </Route>
          </Switch>
        </Layout.Scroll>
      </Layout.Container>
    </div>
  );
};

const mapDispatchToProps = {
  fetchRoles,
  fetchUserRoles,
  createRole,
  fetchUsers,
  fetchPlaybooks,
  showSnackbarMessage
};

const mapStateToProps = (state: RootState) => ({
  roles: state.playbooks.roles,
  userRoles: state.playbooks.userRoles,
  usersForRole: state.playbooks.usersForRole,
  users: state.users.data,
  playbooks: state.playbooks.playbooks,
  playbookStatus: state.playbooks.status
});

// eslint-disable-next-line no-class-assign
const ConnectedPlaybooks = connect(
  mapStateToProps,
  mapDispatchToProps
)(Playbooks);

export default withFeatureFlagsConsumer()(withRouter(ConnectedPlaybooks));
