import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { axiosProxy } from "../../axios/AxiosProxy";
import { auth } from "../../auth/Auth";
import { DataRole, EmbeddedDashboard } from "../../models/dataRole";
import {Heading, PageHeading, Stack} from "@operata/adagio";

type EmbeddedPlaybookProps = {
  role?: DataRole;
  playbooks: EmbeddedDashboard[];
};

type EmbeddedPlaybookParams = {
  id: string;
};

export default function EmbeddedPlaybook({ role, playbooks }: EmbeddedPlaybookProps) {
  const { id } = useParams<EmbeddedPlaybookParams>();
  const [playbook, setPlaybook] = useState<EmbeddedDashboard | undefined>(undefined);

  useEffect(
    () => {
      const f = async () => {
        if (!role || !playbooks) {
          return;
        }

        const foundPlaybook = playbooks.find(p => p.id === parseInt(id, 10));
        if (!foundPlaybook) {
          return;
        }

        setPlaybook(foundPlaybook);

        const containerEl = document.getElementById("my-superset-container");
        if (!containerEl) {
          return;
        }

        const embeddedDashboard = await getEmbeddedDashboard(foundPlaybook);
        embedDashboard({
          id: embeddedDashboard.embeddedID,
          supersetDomain: supersetUrl(),
          mountPoint: containerEl,
          fetchGuestToken: () => getGuestToken(role, foundPlaybook),
          dashboardUiConfig: {
            hideTitle: true,
            filters: {
              expanded: false
            },
            urlParams: []
          }
        });
      };
      f();
    },
    [id, role, playbooks]
  );

  if (!role) {
    return <></>;
  }

  return (
      <>
        <div id={"my-superset-container"}/>
      </>
  );
}

async function getGuestToken(role: DataRole, playbook: EmbeddedDashboard) {
  return axiosProxy
    .getInstance()
    .post(
        `/dataRoles/${role.id}/token/${playbook.id}`)
    .then(resp => resp.data);
}

async function getEmbeddedDashboard(dashboard: EmbeddedDashboard) {
  return axiosProxy
    .getInstance()
    .get(
        `/dashboards/${dashboard.id}`)
    .then(resp => resp.data);
}

function supersetUrl() {
  if (!process.env.REACT_APP_EMBEDDED_BI_URL) {
    throw new Error("REACT_APP_EMBEDDED_BI_URL is not set");
  }
  return process.env.REACT_APP_EMBEDDED_BI_URL.replace(
    ".operata.io",
    auth.getRegionalUrlPrefix() + ".operata.io"
  );
}
