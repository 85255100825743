import {
  AUDIT_LOGS_RECEIVED,
  ERROR_AUDIT_LOGS_RETRIEVAL
} from "../actions/audit";

const audit = (
  state = {
    loading: false,
    auditLogs: [],
    auditLogsRecCount: 0
  },
  action
) => {
  switch (action.type) {
    case AUDIT_LOGS_RECEIVED:
      return Object.assign({}, state, {
        auditLogs: action.logs,
        auditLogsRecCount: action.recordCount,
        loading: false,
        status: AUDIT_LOGS_RECEIVED
      });
    case ERROR_AUDIT_LOGS_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_AUDIT_LOGS_RETRIEVAL
      });
    default:
      return state;
  }
};

export default audit;
