import { axiosProxy } from "../axios/AxiosProxy";
import { SHOW_PROGRESS } from "./progress";
import { auth } from "../auth/Auth";

export const GROUPS_RECEIVED = "GROUPS_RECEIVED";
export const GROUP_RECEIVED = "GROUP_RECEIVED";
export const GROUP_CREATED = "GROUP_CREATED";
export const GROUP_CONFIG_RECEIVED = "GROUP_CONFIG_RECEIVED";
export const ERROR_GROUP_CONFIG_RECEIVED = "ERROR_GROUP_CONFIG_RECEIVED";
export const ERROR_GROUP_CREATED = "ERROR_GROUP_CREATED";
export const ERROR_GROUP_UPDATED = "ERROR_GROUP_UPDATED";
export const ERROR_GROUP_REMOVE = "ERROR_GROUP_REMOVE";
export const ERROR_GROUP_RESTORE = "ERROR_GROUP_RESTORE";
export const GROUP_UPDATED = "GROUP_UPDATED";
export const GROUP_REMOVED = "GROUP_REMOVED";
export const GROUP_RESTORED = "GROUP_RESTORED";
export const IMPORT_DASHBOARD_COMPLETE = "IMPORT_DASHBOARD_COMPLETE";
export const REINDEX_GROUP_DATA_COMPLETE = "REINDEX_GROUP_DATA_COMPLETE";
export const ERROR_REINDEX_GROUP_DATA = "ERROR_REINDEX_GROUP_DATA";
export const ERROR_DASHBOARD_IMPORT = "ERROR_DASHBOARD_IMPORT";
export const RESET_DASHBOARD_IMPORT_STATUS = "RESET_DASHBOARD_STATUS";
export const RESET_REINDEX_GROUP_DATA_STATUS =
  "RESET_REINDEX_GROUP_DATA_STATUS";
export const OPEN_GROUP_SWITCH = "OPEN_GROUP_SWITCH";
export const CLOSE_GROUP_SWITCH = "CLOSE_GROUP_SWITCH";
export const CANVAS_ENABLED = "CANVAS_ENABLED";
export const ERROR_CANVAS_ENABLE = "ERROR_CANVAS_ENABLE";
export const CLEAR_STATE = "CLEAR_STATE";
export const GROUP_DASHBOARD_RECEIVED = "GROUP_DASHBOARD_RECEIVED";
export const ERROR_GROUP_DASHBOARD = "ERROR_GROUP_DASHBOARD";

export const clearState = () => ({
  type: CLEAR_STATE
});

export const groupActionRequested = () => ({
  type: SHOW_PROGRESS
});

export const groupsReceived = groupList => ({
  type: GROUPS_RECEIVED,
  groupList: groupList
});

export const groupReceived = group => ({
  type: GROUP_RECEIVED,
  group: group
});

export const groupCreated = group => ({
  type: GROUP_CREATED,
  group: group
});

export const errorGroupCreated = (message1, message2) => ({
  type: ERROR_GROUP_CREATED,
  message: message2 || message1
});

export const groupUpdated = group => ({
  type: GROUP_UPDATED,
  group: group
});

export const errorGroupUpdated = (message1, message2) => ({
  type: ERROR_GROUP_UPDATED,
  message: message2 || message1
});

export const importDashboardComplete = () => ({
  type: IMPORT_DASHBOARD_COMPLETE
});

export const reindexGroupDataComplete = () => ({
  type: REINDEX_GROUP_DATA_COMPLETE
});

export const errorImportDashboard = message => ({
  type: ERROR_DASHBOARD_IMPORT,
  message: message
});

export const errorReindexGroupData = message => ({
  type: ERROR_REINDEX_GROUP_DATA,
  message: message
});

export const resetDashboardImportStatus = () => ({
  type: RESET_DASHBOARD_IMPORT_STATUS
});

export const resetReindexGroupDataStatus = () => ({
  type: RESET_REINDEX_GROUP_DATA_STATUS
});

export const groupRemoved = id => ({
  type: GROUP_REMOVED,
  id: id
});

export const canvasEnabled = (id, message) => ({
  type: CANVAS_ENABLED,
  id: id,
  message: message
});

export const groupRestored = id => ({
  type: GROUP_RESTORED,
  id: id
});

export const openGroupSwitch = () => ({
  type: OPEN_GROUP_SWITCH
});

export const closeGroupSwitch = () => ({
  type: CLOSE_GROUP_SWITCH
});

export const groupConfigReceived = config => ({
  type: GROUP_CONFIG_RECEIVED,
  config: config
});

export const errorGroupConfigReceived = () => ({
  type: ERROR_GROUP_CONFIG_RECEIVED
});

export const errorRemoveGroup = () => ({
  type: ERROR_GROUP_REMOVE
});

export const errorCanvasEnable = message => ({
  type: ERROR_CANVAS_ENABLE,
  message: message
});

export const errorRestoreGroup = () => ({
  type: ERROR_GROUP_RESTORE
});

export const errorGroupDashboard = () => ({
  type: ERROR_GROUP_DASHBOARD
});

export const groupDashboardReceived = dashboard => ({
  type: GROUP_DASHBOARD_RECEIVED,
  data: dashboard
});

//redux-thunk
export const fetchGroups = type => {
  return dispatch => {
    dispatch(groupActionRequested());

    if (type == "active") {
      return getGroups().then(resp => {
        dispatch(groupsReceived(resp.data));
      });
    } else {
      return getArchivedGroups().then(resp => {
        dispatch(groupsReceived(resp.data));
      });
    }
  };
};

export const fetchGroup = id => {
  return dispatch => {
    dispatch(groupActionRequested());
    return getGroupById(id).then(resp => {
      dispatch(groupReceived(resp.data));
    });
  };
};

export const createGroup = group => {
  if (!group.customerName || group.customerName == "") {
    var groupSubmit = {
      name: group.name,
      secret: group.secret,
      region: group.region,
      customerName: group.newCustomerName || group.existingCustomerName,
      ccaasProvider: group.ccaasProvider
    };
    if (group.customerID && group.customerID !== "") {
      groupSubmit.customerID = group.customerID;
    }
    group = groupSubmit;
  }

  return dispatch => {
    dispatch(groupActionRequested());

    let user = auth.getUserProfile();
    group.linkedConfig = {
      email: user.email,
      name: user.name
    };
    return postGroup(group)
      .then(() => {
        dispatch(groupCreated(group));
      })
      .catch(err => {
        dispatch(
          errorGroupCreated(
            "An error occurred while creating the group",
            err.response.data
          )
        );
      });
  };
};

export const updateGroup = group => {
  return dispatch => {
    dispatch(groupActionRequested());

    if (group.id && group.id.length > 0) {
      let user = auth.getUserProfile();

      group.linkedConfig = {
        email: user.email,
        name: user.name
      };
      return putGroup(group).then(resp => {
        dispatch(groupUpdated(resp.data));
      });
    } else {
      dispatch(errorGroupUpdated("Group ID is missing"));
    }
  };
};

export const importDashboard = groupId => {
  return dispatch => {
    dispatch(groupActionRequested());
    if (groupId && groupId.length > 0) {
      return putDashboard(groupId)
        .then(resp => {
          dispatch(importDashboardComplete(resp.data));
        })
        .catch(() => {
          dispatch(
            errorImportDashboard("An error occurred while importing dashboards")
          );
        });
    }
  };
};

export const updateCanvas = id => {
  return dispatch => {
    dispatch(groupActionRequested());
    return canvasUpdate(id)
      .then(resp => {
        dispatch(canvasEnabled(id, resp.data));
      })
      .catch(err => {
        if (!err.response) {
          dispatch(errorCanvasEnable("Error while updating Canvas "));
        } else {
          dispatch(errorCanvasEnable(err.response.data));
        }
      });
  };
};

export const fetchGroupConfig = () => {
  return dispatch => {
    dispatch(groupActionRequested());
    return getGroupConfig()
      .then(resp => {
        dispatch(groupConfigReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorGroupConfigReceived());
      });
  };
};

export const fetchGroupDashboardDetails = () => {
  return dispatch => {
    dispatch(groupActionRequested());
    return getDashboardMetadata("CallEvents")
      .then(resp => {
        dispatch(groupDashboardReceived(resp.data));
      })
      .catch(() => {
        dispatch(errorGroupDashboard());
      });
  };
};

const getGroups = () => {
  return axiosProxy.getInstance().get("/groups");
};

const getArchivedGroups = () => {
  return axiosProxy.getInstance().get("/groups/archived");
};

export const getGroupById = id => {
  return axiosProxy.getInstance().get("/groups/" + id);
};

const postGroup = group => {
  return axiosProxy.getInstance().post("/groups", group);
};

const putGroup = group => {
  return axiosProxy.getInstance().put("/groups", group);
};

const putDashboard = id => {
  return axiosProxy.getInstance().put("/groups/" + id + "/importdashboard", {});
};

const getDashboardMetadata = dashboardName => {
  return axiosProxy.getInstance().get(`/groups/dashboard/${dashboardName}`);
};

const canvasUpdate = group => {
  return axiosProxy.getInstance().post("/groups/" + group + "/bi/configure");
};

const getGroupConfig = () => {
  return axiosProxy.getInstance().get("/simulate/numbers");
};
