import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import "../Tabs/Tabs.scss";
import GroupList from "./GroupList";
import Billing from "../Billing/Billing";
import Marketplace from "../Billing/Marketplace";
import BillingAuditLogs from "../Billing/BillingAuditLogs";
import { auth, USER_ROLE_SUPER } from "../../auth/Auth";

import {
  Stack,
  Section,
  Tabs,
  PageHeading,
  ButtonRefactored as Button,
  AddIcon
} from "@operata/adagio";
import { Route, Switch, withRouter } from "react-router-dom";

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class GroupListTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, value) {
    this.setState({ value });
  }

  handleClick = () => {
    this.props.history.push("/admin/group/0");
  };

  render() {
    let { path } = this.props.match;

    return (
      <>
        <Stack direction="col" gap="24">
          <PageHeading title="Group Management">
            <Button
              type="primary"
              size="large"
              iconAfter={<AddIcon />}
              onClick={this.handleClick}
            >
              Provision New Group
            </Button>
          </PageHeading>
          <Section>
            <Tabs padding="16">
              <Tabs.Tab exact to={path} label="Active Groups" />
              <Tabs.Tab to={`${path}/archived`} label="Archived Groups" />
              {auth.hasPermission([USER_ROLE_SUPER]) && (
                <Tabs.Tab to={`${path}/billing`} label="Billing" />
              )}
              {auth.hasPermission([USER_ROLE_SUPER]) && (
                <Tabs.Tab
                  to={`${path}/billingauditlogs`}
                  label="Billing Audit Logs"
                />
              )}
              {auth.hasPermission([USER_ROLE_SUPER]) && (
                <Tabs.Tab to={`${path}/marketplace`} label="Marketplace" />
              )}
            </Tabs>

            <Section.Content padding="medium">
              <div style={{ minHeight: "50em" }}>
                <Switch>
                  <Route exact path={path}>
                    <GroupList type="active" />
                  </Route>
                  <Route path={`${path}/archived`}>
                    <GroupList type="archived" />
                  </Route>
                  {auth.hasPermission([USER_ROLE_SUPER]) && (
                    <Route path={`${path}/billing`}>
                      <Billing />
                    </Route>
                  )}
                  {auth.hasPermission([USER_ROLE_SUPER]) && (
                    <Route path={`${path}/billingauditlogs`}>
                      <BillingAuditLogs />
                    </Route>
                  )}
                  {auth.hasPermission([USER_ROLE_SUPER]) && (
                    <Route path={`${path}/marketplace`}>
                      <Marketplace />
                    </Route>
                  )}
                </Switch>
              </div>
            </Section.Content>
          </Section>
        </Stack>
      </>
    );
  }
}

GroupListTabs.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withRouter(GroupListTabs);
