import {
  AGENT_CCP_LOGS_RECEIVED,
  ERROR_AGENT_CCP_LOGS_RETRIEVAL
} from "../actions/agent";
import { AgentLog } from "../models/agent";
import {AnyAction} from "redux";

export type AgentState = {
  loading: boolean;
  logs: AgentLog[];
  recordCount: number;
  status?: string;
};

const agent = (
  state: AgentState = {
    loading: false,
    logs: [],
    recordCount: 0
  },
  action: AnyAction
) => {
  switch (action.type) {
    case AGENT_CCP_LOGS_RECEIVED:
      return Object.assign({}, state, {
        logs: action.logs,
        recordCount: action.recordCount,
        loading: false,
        status: AGENT_CCP_LOGS_RECEIVED
      });
    case ERROR_AGENT_CCP_LOGS_RETRIEVAL:
      return Object.assign({}, state, {
        loading: false,
        status: ERROR_AGENT_CCP_LOGS_RETRIEVAL
      });
    default:
      return state;
  }
};

export default agent;
