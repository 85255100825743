import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import Ranges from "./ranges/Ranges";
import DatePicker from "./date_picker/DatePicker";
import { isValidTimeChange } from "./utils/TimeFunctionUtils";
import { datePicked, pastMaxDate } from "./utils/DateSelectedUtils";
import WrapperContext from "../WrapperContext";

export const ModeEnum = Object.freeze({ start: "start", end: "end" });
export var momentFormat = "DD-MM-YYYY HH:mm";

class DateTimeRangePicker extends React.Component {
  constructor(props) {
    super(props);
    let ranges = {};
    let customRange = { "Custom Range": "Custom Range" };
    Object.assign(ranges, this.props.ranges, customRange);

    if (this.props.local && this.props.local.format) {
      momentFormat = this.props.local.format;
    }

    this.state = {
      selectedRange: 0,
      selectingModeFrom: true,
      ranges: ranges,
      start: this.props.start,
      startLabel: this.props.start.format(momentFormat),
      end: this.props.end,
      endLabel: this.props.end.format(momentFormat),
      focusDate: false
    };

    this.bindToFunctions();
  }

  getRangePosition(selectedRangeKey) {
    let retIndex = 0;
    {
      Object.keys(this.state.ranges).map((range, i) => {
        if (range === selectedRangeKey) {
          retIndex = i;
        }
      });
    }

    return retIndex;
  }

  bindToFunctions() {
    this.rangeSelectedCallback = this.rangeSelectedCallback.bind(this);
    this.dateSelectedNoTimeCallback = this.dateSelectedNoTimeCallback.bind(
      this
    );
    this.timeChangeCallback = this.timeChangeCallback.bind(this);
    this.dateTextFieldCallback = this.dateTextFieldCallback.bind(this);
    this.onChangeDateTextHandlerCallback = this.onChangeDateTextHandlerCallback.bind(
      this
    );
    this.changeSelectingModeCallback = this.changeSelectingModeCallback.bind(
      this
    );
    this.applyCallback = this.applyCallback.bind(this);
    this.keyboardCellCallback = this.keyboardCellCallback.bind(this);
    this.focusOnCallback = this.focusOnCallback.bind(this);
    this.cellFocusedCallback = this.cellFocusedCallback.bind(this);
  }

  componentDidMount() {
    this.setToRangeValue(this.state.start, this.state.end);
    let selectedRangeIndex = this.getRangePosition(
      this.context.state.selectedRange
    );
    this.setState({ selectedRange: selectedRangeIndex });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.start.isSame(prevProps.start)) {
      this.updateStartEndAndLabels(this.props.start, this.state.end);
    } else if (!this.props.end.isSame(prevProps.end)) {
      this.updateStartEndAndLabels(this.state.start, this.props.end);
    }
  }

  applyCallback() {
    this.props.applyCallback(this.state.start, this.state.end);
    this.props.changeVisibleState();
  }

  checkAutoApplyActiveApplyIfActive(startDate, endDate) {
    if (this.props.autoApply) {
      this.props.applyCallback(startDate, endDate);
    }
  }

  rangeSelectedCallback(index, value) {
    // If Past Max Date Dont allow update
    let start;
    let end;
    if (value !== "Custom Range") {
      start = this.state.ranges[value][0];
      end = this.state.ranges[value][1];
      if (
        pastMaxDate(start, this.props.maxDate, true) ||
        pastMaxDate(end, this.props.maxDate, true)
      ) {
        return false;
      }
    }
    // Else update state to new selected index and update start and end time
    this.setState({ selectedRange: index });
    if (value !== "Custom Range") {
      this.updateStartEndAndLabels(start, end);
    }
    if (this.props.rangeCallback) {
      this.props.rangeCallback(index, value);
    }

    // if (value !== "Custom Range") {
    //   setTimeout(() => {
    //     this.checkAutoApplyActiveApplyIfActive(start, end);
    //   }, 100);
    // }
  }

  setToRangeValue(startDate, endDate) {
    let rangesArray = Object.values(this.state.ranges);
    for (let i = 0; i < rangesArray.length; i++) {
      if (rangesArray[i] === "Custom Range") {
        continue;
      } else if (
        rangesArray[i][0].isSame(startDate, "minutes") &&
        rangesArray[i][1].isSame(endDate, "minutes")
      ) {
        this.setState({ selectedRange: i });
        return;
      }
    }
    this.setToCustomRange();
  }

  setToCustomRange() {
    let rangesArray = Object.values(this.state.ranges);
    for (let i = 0; i < rangesArray.length; i++) {
      if (rangesArray[i] === "Custom Range") {
        this.setState({ selectedRange: i });
      }
    }
  }

  updateStartEndAndLabels(newStart, newEnd) {
    this.setState({
      start: newStart,
      startLabel: newStart.format(momentFormat),
      end: newEnd,
      endLabel: newEnd.format(momentFormat)
    });
  }

  dateSelectedNoTimeCallback(cellDate) {
    let newDates = datePicked(
      this.state.start,
      this.state.end,
      cellDate,
      this.state.selectingModeFrom
    );
    let startDate = newDates.startDate;
    let endDate = newDates.endDate;
    let newStart = this.duplicateMomentTimeFromState(startDate, true);
    let newEnd = this.duplicateMomentTimeFromState(endDate, false);
    this.updateStartEndAndLabels(newStart, newEnd);
    this.setToRangeValue(newStart, newEnd);
    this.setState(prevState => ({
      selectingModeFrom: !prevState.selectingModeFrom
    }));

    this.checkAutoApplyActiveApplyIfActive(newStart, newEnd);
  }

  changeSelectingModeCallback(selectingModeFromParam) {
    this.setState({ selectingModeFrom: selectingModeFromParam });
  }

  duplicateMomentTimeFromState(date, startDate) {
    let state;
    if (startDate) {
      state = this.state.start;
    } else {
      state = this.state.end;
    }
    let newDate = [
      date.year(),
      date.month(),
      date.date(),
      state.hours(),
      state.minutes()
    ];
    return moment(newDate);
  }

  timeChangeCallback(newHour, newMinute, mode) {
    if (mode === "start") {
      this.updateStartTime(newHour, newMinute, mode);
    } else if (mode === "end") {
      this.updateEndTime(newHour, newMinute, mode);
    }
  }

  updateStartTime(newHour, newMinute, mode) {
    this.updateTime(
      this.state.start,
      newHour,
      newMinute,
      mode,
      "start",
      "startLabel"
    );
  }

  updateEndTime(newHour, newMinute, mode) {
    this.updateTime(
      this.state.end,
      newHour,
      newMinute,
      mode,
      "end",
      "endLabel"
    );
  }

  updateTime(
    origDate,
    newHour,
    newMinute,
    mode,
    stateDateToChangeName,
    stateLabelToChangeName
  ) {
    let date = moment(origDate);
    date.hours(newHour);
    date.minutes(newMinute);
    // If Past Max Date Dont allow update
    if (pastMaxDate(date, this.props.maxDate, true)) {
      return false;
    }
    // If Valid Time Change allow the change else set new start and end times
    // to be minute ahead/behind the new date
    if (isValidTimeChange(mode, date, this.state.start, this.state.end)) {
      this.setState({
        [stateDateToChangeName]: date,
        [stateLabelToChangeName]: date.format(momentFormat)
      });
      this.updateTimeCustomRangeUpdator(stateDateToChangeName, date);
      if (stateDateToChangeName === "end") {
        this.checkAutoApplyActiveApplyIfActive(this.state.start, date);
      } else {
        this.checkAutoApplyActiveApplyIfActive(date, this.state.end);
      }
    } else {
      let newDate = moment(date);
      if (mode === "start") {
        newDate.add(1, "minute");
        this.updateStartEndAndLabels(date, newDate);
        this.setToRangeValue(date, newDate);
        this.checkAutoApplyActiveApplyIfActive(date, newDate);
      } else {
        newDate.subtract(1, "minute");
        this.updateStartEndAndLabels(newDate, date);
        this.setToRangeValue(newDate, date);
        this.checkAutoApplyActiveApplyIfActive(newDate, date);
      }
    }
  }

  updateTimeCustomRangeUpdator(stateDateToChangeName, date) {
    if (stateDateToChangeName === "start") {
      this.setToRangeValue(date, this.state.end);
    } else {
      this.setToRangeValue(this.state.start, date);
    }
  }

  dateTextFieldCallback(mode) {
    if (mode === "start") {
      let newDate = moment(this.state.startLabel, momentFormat);
      let isValidNewDate = newDate.isValid();
      let isSameOrBeforeEnd = newDate.isSameOrBefore(this.state.end, "minute");
      let isAfterEndDate = newDate.isAfter(this.state.end);
      this.updateDate(
        mode,
        newDate,
        isValidNewDate,
        isSameOrBeforeEnd,
        isAfterEndDate,
        "start",
        "startLabel"
      );
    } else {
      let newDate = moment(this.state.endLabel, momentFormat);
      let isValidNewDate = newDate.isValid();
      let isBeforeStartDate = newDate.isBefore(this.state.start);
      let isSameOrAfterStartDate = newDate.isSameOrAfter(
        this.state.start,
        "minute"
      );
      this.updateDate(
        mode,
        newDate,
        isValidNewDate,
        isSameOrAfterStartDate,
        isBeforeStartDate,
        "end",
        "endLabel"
      );
    }
  }

  updateDate(
    mode,
    newDate,
    isValidNewDate,
    isValidDateChange,
    isInvalidDateChange,
    stateDateToChangeName,
    stateLabelToChangeName
  ) {
    // If new date past max date dont allow change
    if (pastMaxDate(newDate, this.props.maxDate, true)) {
      this.updateStartEndAndLabels(this.state.start, this.state.end);
      return false;
    }
    // Else if date valid and date change valid update the date,
    // if date invalid go into update invalid mode, adds/subtract 1 days from start/stop value
    if (isValidNewDate && isValidDateChange) {
      this.setState({
        [stateDateToChangeName]: newDate,
        [stateLabelToChangeName]: newDate.format(momentFormat)
      });
      this.updateTimeCustomRangeUpdator(stateDateToChangeName, newDate);
      if (stateDateToChangeName === "end") {
        this.checkAutoApplyActiveApplyIfActive(this.state.start, newDate);
      } else {
        this.checkAutoApplyActiveApplyIfActive(newDate, this.state.end);
      }
    } else if (isValidNewDate && isInvalidDateChange) {
      this.updateInvalidDate(mode, newDate);
    } else if (!isValidNewDate) {
      this.updateStartEndAndLabels(this.state.start, this.state.end);
    }
  }

  updateInvalidDate(mode, newDate) {
    if (mode === "start") {
      let newEndDate = moment(newDate).add(1, "day");
      this.updateLabelsAndRangeValues(newDate, newEndDate);
      this.checkAutoApplyActiveApplyIfActive(newDate, newEndDate);
    } else {
      let newStartDate = moment(newDate).subtract(1, "day");
      this.updateStartEndAndLabels(newStartDate, newDate);
      this.checkAutoApplyActiveApplyIfActive(newStartDate, newDate);
    }
  }

  updateLabelsAndRangeValues(startDate, endDate) {
    this.updateStartEndAndLabels(startDate, endDate);
    this.setToRangeValue(startDate, endDate);
  }

  onChangeDateTextHandlerCallback(newValue, mode) {
    if (mode === "start") {
      this.setState({
        startLabel: newValue
      });
    } else if (mode === "end") {
      this.setState({
        endLabel: newValue
      });
    }
  }

  keyboardCellCallback(originalDate, newDate) {
    let startDate;
    let endDate;
    if (originalDate.isSame(this.state.start, "day")) {
      startDate = this.duplicateMomentTimeFromState(newDate, true);
      endDate = moment(this.state.end);
    } else {
      startDate = moment(this.state.start);
      endDate = this.duplicateMomentTimeFromState(newDate, false);
    }

    if (startDate.isBefore(endDate, "day")) {
      this.updateStartEndAndLabels(startDate, endDate);
    } else {
      this.updateStartEndAndLabels(endDate, startDate);
    }

    this.checkAutoApplyActiveApplyIfActive(startDate, endDate);
  }

  focusOnCallback(date) {
    if (date) {
      this.setState({
        focusDate: date
      });
    } else {
      this.setState({
        focusDate: false
      });
    }
  }

  cellFocusedCallback(date) {
    if (date.isSame(this.state.start, "day")) {
      this.changeSelectingModeCallback(true);
    } else if (date.isSame(this.state.end, "day")) {
      this.changeSelectingModeCallback(false);
    }
  }

  renderStartDate() {
    return (
      <DatePicker
        label="From Date"
        date={this.state.start}
        otherDate={this.state.end}
        mode={ModeEnum.start}
        dateSelectedNoTimeCallback={this.dateSelectedNoTimeCallback}
        timeChangeCallback={this.timeChangeCallback}
        dateTextFieldCallback={this.dateTextFieldCallback}
        keyboardCellCallback={this.keyboardCellCallback}
        focusOnCallback={this.focusOnCallback}
        focusDate={this.state.focusDate}
        cellFocusedCallback={this.cellFocusedCallback}
        onChangeDateTextHandlerCallback={this.onChangeDateTextHandlerCallback}
        dateLabel={this.state.startLabel}
        selectingModeFrom={this.state.selectingModeFrom}
        changeSelectingModeCallback={this.changeSelectingModeCallback}
        applyCallback={this.applyCallback}
        maxDate={this.props.maxDate}
        local={this.props.local}
      />
    );
  }

  renderEndDate() {
    return (
      <DatePicker
        label="To Date"
        date={this.state.end}
        otherDate={this.state.start}
        mode={ModeEnum.end}
        dateSelectedNoTimeCallback={this.dateSelectedNoTimeCallback}
        timeChangeCallback={this.timeChangeCallback}
        dateTextFieldCallback={this.dateTextFieldCallback}
        keyboardCellCallback={this.keyboardCellCallback}
        focusOnCallback={this.focusOnCallback}
        focusDate={this.state.focusDate}
        cellFocusedCallback={this.cellFocusedCallback}
        onChangeDateTextHandlerCallback={this.onChangeDateTextHandlerCallback}
        dateLabel={this.state.endLabel}
        changeVisibleState={this.props.changeVisibleState}
        selectingModeFrom={this.state.selectingModeFrom}
        changeSelectingModeCallback={this.changeSelectingModeCallback}
        applyCallback={this.applyCallback}
        maxDate={this.props.maxDate}
        local={this.props.local}
        enableButtons
        autoApply={this.props.autoApply}
      />
    );
  }

  render() {
    return (
      <WrapperContext.Consumer>
        {context => (
          <React.Fragment>
            {context.state.tab === 0 && (
              <div className="calendar__wrapper">
                {this.renderStartDate()}
                {this.renderEndDate()}
              </div>
            )}
            {context.state.tab === 1 && (
              <React.Fragment>
                <Ranges
                  ranges={this.state.ranges}
                  selectedRange={this.state.selectedRange}
                  rangeSelectedCallback={this.rangeSelectedCallback}
                  screenWidthToTheRight={this.props.screenWidthToTheRight}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </WrapperContext.Consumer>
    );
  }
}

DateTimeRangePicker.propTypes = {
  ranges: PropTypes.object.isRequired,
  start: momentPropTypes.momentObj,
  end: momentPropTypes.momentObj,
  local: PropTypes.object.isRequired,
  applyCallback: PropTypes.func.isRequired,
  rangeCallback: PropTypes.func,
  autoApply: PropTypes.bool,
  maxDate: momentPropTypes.momentObj,
  changeVisibleState: PropTypes.func.isRequired,
  screenWidthToTheRight: PropTypes.number.isRequired
};

DateTimeRangePicker.contextType = WrapperContext;

export { DateTimeRangePicker };
