import React from "react";
import {
  Modal,
  Stack,
  ButtonRefactored as Button,
  InputText,
  Typography,
} from "@operata/adagio";
import { formatString } from "../ApiTokenConfig";

interface ValueType {
  lastAccessed: string;
  token: string;
  createdOn: string;
  label: string;
}

export interface ModalRevoke {
  onClose: () => void;
  handleRevoke: () => void;
  token: ValueType;
}

export function ModalApiRevoke({ onClose, handleRevoke, token }: ModalRevoke) {
  return (
    <Modal isOpen onClose={onClose} width="micro">
      <Modal.Header border>Revoke Secret Key</Modal.Header>
      <Modal.Content>
        <Stack direction="column" gap="small" padding="medium">
          <Typography>
            <p>
              This API key will immediately be disabled. API requests made using
              this key will be rejected, which could cause any systems still
              depending on it to break. Once revoked, you&apos;ll no longer be
              able to view or modify this API key.
            </p>
          </Typography>
          <InputText
            label="Secret Key"
            value={formatString(token.token)}
            monospace
          />
        </Stack>
      </Modal.Content>
      <Modal.Footer background>
        <Stack direction="row" gap="tiny" justify="end">
          <Button onClick={onClose} size="medium" type="tertiary">
            Cancel
          </Button>
          <Button
            onClick={handleRevoke}
            size="medium"
            type="primary"
            tone="danger"
          >
            Revoke Key
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}
