import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import { Field } from "redux-form";
import renderFromHelper from "./util";

class OpTextField extends React.Component {
  renderer = ({
    input,
    label,
    meta: { touched, invalid, error },
    children,
    ...custom
  }) => (
    <FormControl error={touched && error && error.length > 0}>
      <InputLabel htmlFor={this.props.name}>{this.props.label}</InputLabel>
      <Input
        error={touched && invalid}
        {...input}
        {...custom}
        autoComplete={this.props.autoComplete}
        type={this.props.type}
      />
      {renderFromHelper({ touched, error })}
    </FormControl>
  );

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.renderer}
        label={this.props.label}
        {...this.props}
      />
    );
  }
}

export default OpTextField;
