import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  LinkTab,
  TabContainer,
  Tabs,
  TabsWrapper
} from "@operata/ui-components";
import {
  ERROR_JOB_DETAILS_RECEIVED,
  fetchInsights,
  fetchJobDetails
} from "../../../actions/gsm";
import { showSnackbarMessage, SNACKBAR_ERROR } from "../../../actions/snackbar";
import Overview from "./Overview";
import IntelligenceQualityTab from "./IntelligenceQualityTab";
import AgentNetworkSoftphoneTab from "./AgentNetworkSoftphoneTab";
import AudioQualityTab from "./AudioQualityTab/AudioQualityTab";

import "./CallReport.scss";

const CallReport = props => {
  const { job, insights, status, message } = useSelector(state => state.gsm);

  const [state, setState] = useState({
    selectedTab: 0
  });

  let jobId = props.match.params.jobId;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJobDetails(jobId));
    dispatch(fetchInsights(jobId));
  }, []);

  if (status === ERROR_JOB_DETAILS_RECEIVED) {
    dispatch(showSnackbarMessage(SNACKBAR_ERROR, message));
  }

  return (
    <div className="callReport__wrapper">
      <TabsWrapper>
        <Tabs
          selectedTab={state.selectedTab}
          onChange={(e, val) => setState({ selectedTab: val })}
        >
          <LinkTab label="Overview" href="page1" />
          <LinkTab label="Audio Quality" href="page2" />
          <LinkTab label="Intelligence Quality" href="page3" />
          <LinkTab label="Agent Network" href="page4" />
          <LinkTab label="Contact Flow" href="page5" />
        </Tabs>
        <div className="tab__content">
          <TabContainer selectedTab={state.selectedTab} index={0}>
            <Overview jobDetails={job} insights={insights} />
          </TabContainer>
          <TabContainer selectedTab={state.selectedTab} index={1}>
            <AudioQualityTab jobDetails={job} insights={insights} />
          </TabContainer>
          <TabContainer selectedTab={state.selectedTab} index={2}>
            <IntelligenceQualityTab jobDetails={job} />
          </TabContainer>
          <TabContainer selectedTab={state.selectedTab} index={3}>
            <AgentNetworkSoftphoneTab
              job={job.job}
              softphoneSummary={job.softphoneSummary}
              softphoneEvents={job.softphoneEvents}
            />
          </TabContainer>
          <TabContainer selectedTab={state.selectedTab} index={4}>
            Contact Flow
          </TabContainer>
        </div>
      </TabsWrapper>
    </div>
  );
};

CallReport.propTypes = {
  match: PropTypes.any
};

export default CallReport;
