import React, { useState } from "react";
import {ButtonRefactored as Button, InputSelect, Popover} from "@operata/adagio";
import { DataRole } from "../../models/dataRole";

type RoleSelectorProps = {
  roles: DataRole[];
  activeRoleId: number;
  updateRole: (roleId: number) => void;
}

export default function RoleSelector({ roles, activeRoleId, updateRole }: RoleSelectorProps) {
  const disabled = !roles?.length;
  const placeholder = disabled ? "No Roles Available" : undefined;
  const activeRole = roles.find(role => role.id === activeRoleId);

  return (
      <Popover
         trigger={<Button type="tertiary" dropdown> {activeRole ? activeRole.name : placeholder} </Button>}
      >
           <Popover.Section>
             {roles?.map((role) => (
                <Popover.Menuitem
                   key={role.id}
                   onClick={() => updateRole(role.id)}
                >
                  {role.name}
                </Popover.Menuitem>
             ))}
           </Popover.Section>
      </Popover>
  );
}
