import React from "react";

import PropTypes from "prop-types";

class Label extends React.Component {
  render() {
    return <div className="date__label">{this.props.label}</div>;
  }
}
export default Label;

Label.propTypes = {
  label: PropTypes.string.isRequired
};
