// Supported Playbook URLs
export const PlaybookShortURLs = {
  PLAYBOOK_1: "default",
  PLAYBOOK_2: "issues",
  PLAYBOOK_3: "calloverview",
  PLAYBOOK_4: "agentoverview",
  PLAYBOOK_5: "comparison",
  PLAYBOOK_6: "logserrors",
  PLAYBOOK_7: "technology",
  PLAYBOOK_8: "coverage",
  PLAYBOOK_9: "agentinteractions",
  PLAYBOOK_10: "customerexperience",
  PLAYBOOK_11: "conversationanalytics",
  PLAYBOOK_12: "jabra",
  ALL_DASHBOARDS: "list"
};

// Dashboard Slug Mapping Object
const dashboardSlugMap = {
  [PlaybookShortURLs.PLAYBOOK_1]: groupId => `default_${groupId}`,
  [PlaybookShortURLs.PLAYBOOK_2]: groupId =>
    `${groupId}_Technology-ReportedIssues`,
  [PlaybookShortURLs.PLAYBOOK_3]: groupId =>
    `${groupId}_Technology-CallOverview`,
  [PlaybookShortURLs.PLAYBOOK_4]: groupId =>
    `${groupId}_Technology-AgentOverview`,
  [PlaybookShortURLs.PLAYBOOK_5]: groupId =>
    `${groupId}_Technology-CohortComparison`,
  [PlaybookShortURLs.PLAYBOOK_6]: groupId =>
    `${groupId}_Technology-LogsAndErrors`,
  [PlaybookShortURLs.PLAYBOOK_7]: groupId =>
    `${groupId}_Technology-NetworksApplicationsEquipment`,
  [PlaybookShortURLs.PLAYBOOK_8]: groupId =>
    `${groupId}_Technology-CollectionCoverage`,
  [PlaybookShortURLs.PLAYBOOK_9]: groupId =>
    `${groupId}_Operations-AgentInteractions`,
  [PlaybookShortURLs.PLAYBOOK_10]: groupId =>
    `${groupId}_Operations-CustomerExperience`,
  [PlaybookShortURLs.PLAYBOOK_11]: groupId =>
    `${groupId}_Operations-ConversationalAnalytics`,
  [PlaybookShortURLs.PLAYBOOK_12]: groupId => `${groupId}_Jabra`,
  [PlaybookShortURLs.ALL_DASHBOARDS]: () => `list`
};

// Function to get dashboard slug
export const getDashboardSlug = (playbook, groupId) => {
  const slugGenerator = dashboardSlugMap[playbook];
  return slugGenerator ? slugGenerator(groupId) : "";
};
