import React from "react";
import { Field } from "redux-form";
import renderFromHelper from "./util";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
  labelStyle: {
    marginLeft: "0px"
  }
});

class OpSelect extends React.Component {
  renderer = ({
    input,
    label,
    meta: { touched, invalid, error },
    children,
    ...custom
  }) => (
    <FormControl error={touched && error && error.length > 0}>
      <InputLabel htmlFor={this.props.name}>{this.props.label}</InputLabel>
      <Select error={touched && invalid} {...input} {...custom}>
        {children}
      </Select>
      {renderFromHelper({ touched, error })}
    </FormControl>
  );

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.renderer}
        label={this.props.label}
        {...this.props}
      >
        {this.props.children}
      </Field>
    );
  }
}

export default withStyles(styles)(OpSelect);
