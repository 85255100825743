import React from "react";
import CampaignContext from "../CampaignContext";
import CampaignViewDetails from "../../CampaignViewDetails";

export default class StepCampaign extends React.Component {
  render() {
    return (
      <CampaignContext.Consumer>
        {({
          campaignId,
          type,
          name,
          description,
          tags,
          fromNumber,
          toNumber,
          totalCalls,
          noAnswerTimeout,
          callDuration,
          callsPerSecond,
          answerTreatment
        }) => (
          <div className="campaigns__wizard_padding">
            <CampaignViewDetails
              campaignRun={{
                campaignId,
                type,
                name,
                description,
                tags,
                fromNumber,
                toNumber,
                totalCalls,
                noAnswerTimeout,
                callDuration,
                callsPerSecond,
                answerTreatment
              }}
            />
          </div>
        )}
      </CampaignContext.Consumer>
    );
  }
}
