import React, { useState } from "react";
import InfoIcon from "@material-ui/icons/Info";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import CloseIcon from "@material-ui/icons/Close";
import "./Status.scss";
import Button from "../../Button/Button";

function StatusModal(props) {
  const [isShown, setIsShown] = useState(false);
  return (
    <div className="statusModal__wrapper">
      <div className="statusModal__body">
        <div className="statusModal__body__cxRow">
          <div className="statusModal__body__cxRow__name">
            Customer Experience
          </div>
          <div className="statusModal__body__cxRow__icon">
            <CloseIcon />
          </div>
          <div className="statusModal__body__cxRow__tag">
            Poor Outbound Voice Quality
          </div>
          <div className="statusModal__body__cxRow__info">
            <InfoIcon
              className="info__icon"
              color="primary"
              fontSize="small"
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
            />
            {isShown && <div className="info__box">Example</div>}
          </div>
        </div>
        <div className="statusModal__body__axRow">
          <div className="statusModal__body__axRow__name">Agent Experience</div>
          <div className="statusModal__body__axRow__icon">
            <DoneOutlineIcon />
          </div>
          <div className="statusModal__body__axRow__tag">
            High Transcription Error Rate
          </div>
          <div className="statusModal__body__axRow__info">
            <InfoIcon
              className="info__icon"
              color="primary"
              fontSize="small"
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
            />
            {isShown && <div className="info__box">Example</div>}
          </div>
        </div>
        <div className="statusModal__body__serviceRow">
          <div className="statusModal__body__serviceRow__name">
            Service Availability
          </div>
          <div className="statusModal__body__serviceRow__icon">
            <DoneOutlineIcon />
          </div>
          <div className="statusModal__body__serviceRow__tag">Normal</div>
          <div className="statusModal__body__serviceRow__info">
            <InfoIcon
              className="info__icon"
              color="primary"
              fontSize="small"
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
            />
            {isShown && <div className="info__box">Example</div>}
          </div>
        </div>
      </div>
      <div className="statusModal__footer">
        <div className="statusModal__footer__insightsAction">
          <Button buttonText={"Insights"} className={"btnSolidSmall"} />
        </div>
        <div className="statusModal__footer__runTestAction">
          <Button buttonText={"Run Test"} className={"btnSolidSmall"} />
        </div>
      </div>
    </div>
  );
}

export default StatusModal;
