import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import TableFilter from "./TableFilter";
import TableSearch from "./TableSearch";
import Popover from "./Popover";
import Select from "@material-ui/core/Select";
import TableViewCol from "./TableViewCol";
import MenuItem from "@material-ui/core/MenuItem";
import { OutlinedInput, FormControl, InputLabel } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import { createCSVDownload } from "../utils";

class TableToolbar extends React.Component {
  static propTypes = {
    options: PropTypes.object.isRequired,
    rowSelected: PropTypes.bool,
    onRowsDelete: PropTypes.func,
    classes: PropTypes.object
  };

  state = {
    iconActive: null,
    showSearch: Boolean(this.props.searchText || this.props.options.searchText),
    searchText: this.props.searchText || null,
    actions: ""
  };

  componentDidUpdate(prevProps) {
    if (this.props.searchText !== prevProps.searchText) {
      this.setState({ searchText: this.props.searchText });
    }
  }

  toggleActions = actions => {
    this.setState(prevState => ({ actionsOpen: !prevState.actionsOpen }));
  };

  handleCSVDownload = () => {
    const { data, columns, options } = this.props;
    createCSVDownload(columns, data, options);
  };

  setActiveIcon = iconName => {
    this.setState(() => ({
      showSearch: this.isSearchShown(iconName),
      iconActive: iconName
    }));
  };

  isSearchShown = iconName => {
    let nextVal = false;
    if (this.state.showSearch) {
      if (this.state.searchText) {
        nextVal = true;
      } else {
        const { onSearchClose } = this.props.options;
        if (onSearchClose) onSearchClose();
        nextVal = false;
      }
    } else if (iconName === "search") {
      nextVal = this.showSearch();
    }
    return nextVal;
  };

  getActiveIcon = iconName => {
    return this.state.iconActive !== iconName ? "icon" : "iconActive";
  };

  showSearch = () => {
    !!this.props.options.onSearchOpen && this.props.options.onSearchOpen();
    this.props.setTableAction("onSearchOpen");
    return true;
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  hideSearch = () => {
    const { onSearchClose } = this.props.options;

    if (onSearchClose) onSearchClose();
    this.props.searchTextUpdate(null);

    this.setState(() => ({
      iconActive: null,
      showSearch: false,
      searchText: null
    }));

    this.searchButton.focus();
  };

  handleCustomSelectedRows = selectedRows => {
    if (!Array.isArray(selectedRows)) {
      throw new TypeError(
        `"selectedRows" must be an "array", but it's "${typeof selectedRows}"`
      );
    }

    if (selectedRows.some(row => typeof row !== "number")) {
      throw new TypeError(`Array "selectedRows" must contain only numbers`);
    }

    const { options } = this.props;
    if (selectedRows.length > 1 && options.selectableRows === "single") {
      throw new Error(
        'Can not select more than one row when "selectableRows" is "single"'
      );
    }
    this.props.selectRowUpdate("custom", selectedRows);
  };

  handleSearch = value => {
    this.setState({ searchText: value });
    this.props.searchTextUpdate(value);
  };

  render() {
    const {
      classes,
      data,
      options,
      columns,
      filterData,
      filterList,
      filterUpdate,
      resetFilters,
      toggleViewColumn
    } = this.props;

    const { downloadCsv, print, viewColumns } = options.textLabels.toolbar;
    const { searchText } = this.state;

    return (
      <React.Fragment>
        <div
          className={
            options.search ? "toolbar__wrapper" : "toolbar__hidden_search"
          }
          role={"toolbar"}
          aria-label={"Table Toolbar"}
        >
          <div
            className={
              options.search ? "searchTable__wrapper" : "searchTable__hide"
            }
          >
            <TableSearch
              searchText={searchText}
              onSearch={this.handleSearch}
              onHide={this.hideSearch}
              options={options}
            />
          </div>
          <div
            className={
              options.download || options.print
                ? "actions__toolbar"
                : "actions__toolbar__hide"
            }
          >
            <div className="actions__wrapper">
              <FormControl variant="outlined">
                <InputLabel htmlFor="actions-helper">
                  {this.state.errorMessage_actions
                    ? this.state.errorMessage_actions
                    : "Actions"}
                </InputLabel>
                <Select
                  className="actions__list"
                  name="actions"
                  error={this.state.error_actions}
                  variant="outlined"
                  value={this.state.actions}
                  onChange={event => this.handleChange(event)}
                  input={<OutlinedInput name="actions" id="actions" />}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {options.download && (
                    <MenuItem value={"post"}>
                      <IconButton
                        aria-label={downloadCsv}
                        className="actions__item"
                        onClick={this.handleCSVDownload}
                      >
                        <DownloadIcon />
                        Download to CSV
                      </IconButton>
                    </MenuItem>
                  )}

                  {/* <MenuItem>
                    <IconButton
                      className="actions__item"
                      onClick={this.props.onRowsDelete}
                      aria-label={textLabels.deleteAria}
                    >
                      <DeleteIcon />
                      Delete
                    </IconButton>
                  </MenuItem> */}

                  {options.print && (
                    <MenuItem value={"put"}>
                      <ReactToPrint
                        trigger={() => (
                          <IconButton
                            aria-label={print}
                            className="actions__item"
                          >
                            <PrintIcon />
                            Print
                          </IconButton>
                        )}
                        content={() => this.props.tableRef()}
                      />
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {options.filter && (
          <div className="filter__wrapper">
            {options.filter && (
              <TableFilter
                columns={columns}
                options={options}
                filterList={filterList}
                filterData={filterData}
                onFilterUpdate={filterUpdate}
                onFilterReset={resetFilters}
                setActiveIcon={this.setActiveIcon}
              />
            )}
            {options.viewColumns && (
              <React.Fragment>
                <div className="select__button">
                  <Popover
                    refExit={this.setActiveIcon.bind(null)}
                    trigger={
                      <IconButton
                        aria-label={viewColumns}
                        classes={{
                          root: this.getActiveIcon(classes, "viewcolumns")
                        }}
                        onClick={this.setActiveIcon.bind(null, "viewcolumns")}
                      >
                        <span className="viewColumn__label">Columns</span>
                      </IconButton>
                    }
                    content={
                      <TableViewCol
                        data={data}
                        columns={columns}
                        options={options}
                        onColumnUpdate={toggleViewColumn}
                      />
                    }
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default TableToolbar;
