import React, { useState } from "react";
import {
  ButtonRefactored as Button,
  InputText,
  Modal,
  Stack
} from "@operata/adagio";
import { CreateDataRole } from "../../models/dataRole";

type CreateRoleModalProps = {
  onClose: () => void;
  onSave: (role: CreateDataRole) => void;
};

export default function CreateRoleModal({
  onClose,
  onSave
}: CreateRoleModalProps) {
  const [name, setName] = useState("");

  const role: CreateDataRole = {
    name: name
  };

  const isFormValid = name.length > 0;

  return (
    <div>
      <Modal isOpen={true} onClose={onClose} width="medium">
        <Modal.Header border>Create Role</Modal.Header>
        <Modal.Content>
          <Stack direction="column" gap="medium" padding="medium" block>
            <InputText
              width="100%"
              label={"Name"}
              value={name}
              placeholder={""}
              onChange={(e: any) => setName(e.target.value)}
            />
          </Stack>
          <Stack direction="column" gap="medium" padding="medium" block />
        </Modal.Content>
        <Modal.Footer>
          <Stack direction="row" gap="8" justify="end">
            <Button onClick={onClose} type="tertiary" size="large">
              Cancel
            </Button>
            <Button
              disabled={!isFormValid}
              onClick={() => onSave({ ...role, name })}
              type="primary"
              size="large"
            >
              Create
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
