import { EXPAND_SIDEBAR, COLLAPSE_SIDEBAR } from "../actions/page";

const modal = (state = { sidebarExpanded: true }, action) => {
  switch (action.type) {
    case EXPAND_SIDEBAR:
      return {
        ...state,
        sidebarExpanded: true
      };
    case COLLAPSE_SIDEBAR:
      return {
        ...state,
        sidebarExpanded: false
      };
    default:
      return state;
  }
};

export default modal;
