import { USERS_RECEIVED, USER_REMOVED } from "../actions/users";
import { AnyAction, Reducer } from "redux";
import { UserProfile } from "../models/user";

export type UsersState = {
  loading: boolean;
  data: UserProfile[];
  status?: string;
};

const users: Reducer<UsersState, AnyAction> = (
  state = { loading: false, data: [] },
  action: AnyAction
) => {
  switch (action.type) {
    case USERS_RECEIVED:
      return {
        ...state,
        data: action.userList,
        loading: false,
        status: USERS_RECEIVED
      };
    case USER_REMOVED:
      return { ...state, loading: false, status: USER_REMOVED };
    default:
      return state;
  }
};

export default users;
