import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Button from "../Button/Button";
import { validate } from "./validation";
import OpTextField from "../Generic/OpTextField";
import OpSelect from "../Generic/OpSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { fetchCustomers } from "../../actions/customers";

import {
  fetchGroup,
  createGroup,
  updateGroup,
  GROUP_CREATED,
  GROUP_UPDATED,
  ERROR_GROUP_CREATED,
  clearState
} from "../../actions/groups";
import {
  showSnackbarMessage,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR
} from "../../actions/snackbar";
import { withRouter } from "react-router-dom";
import PropTypes, { bool, string } from "prop-types";

class GroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = { newCustomer: "" };
    this.UNSAFE_componentWillReceiveProps = this.UNSAFE_componentWillReceiveProps.bind(
      this
    );
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if (id && id !== "0") {
      this.props.fetchGroup(id);
    }
    this.props.fetchCustomers();
  }

  isEditGroup() {
    let id = this.props.match.params.id;
    return id && id !== "0";
  }

  handleCancel = () => {
    this.props.history.push("/admin/groups");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.status === GROUP_CREATED) {
      this.props.showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Group creation in progress."
      );
      this.props.history.push("/admin/groups");
    } else if (nextProps.status === GROUP_UPDATED) {
      this.props.showSnackbarMessage(
        SNACKBAR_SUCCESS,
        "Group updated successfully."
      );
      this.props.history.push("/admin/groups");
    } else if (nextProps.status === ERROR_GROUP_CREATED) {
      if (
        nextProps.message.trim() === "error while restarting BI jobs" ||
        nextProps.message.trim() === "error while enabling Canvas"
      ) {
        this.props.showSnackbarMessage(
          SNACKBAR_ERROR,
          "Group created. " + nextProps.message
        );
        this.props.history.push("/admin/groups");
      } else {
        this.props.showSnackbarMessage(SNACKBAR_ERROR, nextProps.message);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  handleNewCustomer(event) {
    const target = event.target;
    if (
      target.value === "New Customer" &&
      (this.state.newCustomer === "false" || this.state.newCustomer === "")
    ) {
      this.setState({ newCustomer: "true" });
    } else if (
      this.state.newCustomer === "true" ||
      this.state.newCustomer === ""
    ) {
      this.setState({ newCustomer: "false" });
    }
  }

  handleChange(event) {
    const target = event.target;
    const propCustomers = this.props.customer;
    propCustomers.forEach(customer => {
      if (customer.customerName === target.value) {
        this.props.change("customerID", customer.id);
      }
    });
  }

  renderCustomerFields(customer) {
    switch (this.state.newCustomer) {
      case "true":
        return (
          <>
            <div className="form__control">
              <OpTextField
                label="New Customer Name"
                name="newCustomerName"
                className="textField"
                placeholder="New Customer Name"
              />
            </div>
            <div className="form__control">
              <OpSelect name="ccaasProvider" label="CCAAS Provider">
                <MenuItem key="provider1" value="AWS">
                  AWS
                </MenuItem>
                <MenuItem key="provider2" value="GENESYS">
                  GENESYS
                </MenuItem>
              </OpSelect>
            </div>
          </>
        );
      case "false":
        return this.customerFields(customer);
      default:
        return this.isEditGroup() ? this.customerFields(customer) : "";
    }
  }

  customerFields = customer => {
    return (
      <>
        <div className="form__control">
          <OpSelect
            name="existingCustomerName"
            label="Exsiting Customer Name"
            onChange={event => this.handleChange(event)}
          >
            {customer &&
              customer.map(option => (
                <MenuItem key={option.id} value={option.customerName}>
                  {option.customerName}
                </MenuItem>
              ))}
          </OpSelect>
        </div>
        <div className="form__control">
          <OpTextField
            label="Customer ID"
            name="customerID"
            className="textField"
            disabled
          />
        </div>
      </>
    );
  };

  render() {
    let { handleSubmit, pristine, submitting, customer } = this.props;
    return (
      <React.Fragment>
        <div className="grid__container">
          <form
            className="grid__column grid__column--four"
            onSubmit={handleSubmit(
              !this.isEditGroup()
                ? this.props.createGroup
                : this.props.updateGroup
            )}
          >
            <div className="form__control">
              <OpTextField
                className="textfield"
                name="name"
                label="Group Name"
                type="text"
              />
            </div>

            {this.isEditGroup() && (
              <>
                <div className="form__control">
                  <OpTextField
                    className="textfield"
                    name="secret"
                    label="Client Secret"
                    type="text"
                  />
                </div>
              </>
            )}

            <OpSelect
              name="region"
              label="Region"
              disabled={this.isEditGroup()}
            >
              <MenuItem key="ap-southeast-2" value="ap-southeast-2">
                Australia
              </MenuItem>
              <MenuItem key="us-east-2" value="us-east-2">
                USA
              </MenuItem>
            </OpSelect>

            <div className="form__control">
              <OpSelect
                name="customerSelect"
                label="Customer"
                onChange={event => this.handleNewCustomer(event)}
              >
                <MenuItem key="cust1" value="Existing Customer">
                  Existing Customer
                </MenuItem>
                {!this.isEditGroup() && (
                  <MenuItem key="cust2" value="New Customer">
                    New Customer
                  </MenuItem>
                )}
              </OpSelect>
            </div>

            {this.renderCustomerFields(customer)}

            <div className="form__control form__control--buttons">
              <Button
                buttonText={"Cancel"}
                className={"btnColouredSmall"}
                disabled={pristine || submitting}
                onClick={this.handleCancel}
              />
              <Button
                type="submit"
                buttonText={"Save"}
                className={"btnSolid"}
                disabled={pristine || submitting}
              />
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  fetchCustomers: fetchCustomers,
  clearState: clearState,
  fetchGroup: fetchGroup,
  createGroup: createGroup,
  updateGroup: updateGroup,
  showSnackbarMessage: showSnackbarMessage
};

export const mapStateToProps = state => ({
  pageTitle:
    state.group.data && state.group.data.id && state.group.data.id.length > 0
      ? "Edit Group"
      : "New Group",
  initialValues:
    state.group.data?.id?.length > 0
      ? {
          name: state.group.data.name,
          secret: state.group.data.secret,
          customerSelect: "Existing Customer",
          existingCustomerName: state.group.data.customerName,
          customerID: state.group.data.customerID,
          ccaasProvider: state.group.data.ccaasProvider,
          region: state.group.data.region || "ap-southeast-2",
          id: state.group.data.id
        }
      : {
          name: "",
          secret: "",
          customerName: "",
          customerID: "",
          ccaasProvider: ""
        },
  loading: state.group.loading,
  status: state.group.status,
  message: state.group.message,
  customer: state.customers.data
});

GroupForm.propTypes = {
  fetchCustomers: PropTypes.func,
  status: string,
  showSnackbarMessage: PropTypes.func,
  message: string,
  handleSubmit: PropTypes.func,
  pristine: bool,
  submitting: bool,
  createGroup: PropTypes.func,
  updateGroup: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  fetchGroup: PropTypes.func,
  clearState: PropTypes.func,
  customer: PropTypes.array,
  initialValues: PropTypes.object,
  change: PropTypes.func
};

// eslint-disable-next-line no-class-assign
GroupForm = reduxForm({
  form: "GroupForm",
  validate,
  asyncBlurFields: ["email"],
  enableReinitialize: true
})(GroupForm);

// eslint-disable-next-line no-class-assign
GroupForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupForm);

export default withRouter(GroupForm);
