import React from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";

const options = {
  chart: {
    type: "scatter",
    zoomType: "xy"
  },
  title: {
    text: ""
  },
  xAxis: {
    title: ["Time"]
  },
  yAxis: {
    title: {
      text: "Score"
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            align: "center",
            verticalAlign: "bottom",
            layout: "horizontal"
          }
        }
      }
    ]
  }
};

const ScatterChart = ({ data }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{ ...options, series: data }}
  />
);

export default ScatterChart;
