import React, { useEffect } from "react";
import { AddIcon, ButtonRefactored as Button, InputSelect, InputText, Modal, Stack, Typography, DeleteIcon } from "@operata/adagio";
import { Condition, DataRole } from "../../models/dataRole";

type EditConditionsModalProps = {
  onClose: () => void;
  onSave: (conditions: Condition[]) => void;
  role: DataRole;
}

type FieldOption = {
  type: "group1" | "group2" | "group3" | "group4" | "group5" | "attributes";
  name: string;
  fieldType: string;
}

const options: FieldOption[] = [
  { type: "group1", name: "Agent Group 1", fieldType: "column" },
  { type: "group2", name: "Agent Group 2", fieldType: "column"},
  { type: "group3", name: "Agent Group 3", fieldType: "column" },
  { type: "group4", name: "Agent Group 4", fieldType: "column" },
  { type: "group5", name: "Agent Group 5", fieldType: "column" },
  { type: "attributes", name: "CTR Attributes", fieldType: "json" },
];

export default function EditConditionsModal({role, onClose, onSave}: EditConditionsModalProps) {
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [isModified, setIsModified] = React.useState(false);
  const [conditions, setConditions] = React.useState<Condition[]>(role.conditions || []);
  const handleUpdate = (condition: Condition, index: number) => {
    const newConditions = [...conditions];
    newConditions[index] = condition;
    setIsModified(true);
    setConditions(newConditions);
  }

  const handleRemove = (index: number) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setIsModified(true);
    setConditions(newConditions);
  }

  const addNewCondition = () => {
    const availableOptions = getAvailableOptions({} as Condition, conditions, options);
    if (availableOptions.length === 0) {
      return;
    }

    const newCondition: Condition = {
      type: availableOptions[0].type,
      field: "",
      value: ""
    };
    setIsModified(true);
    setConditions([...conditions, newCondition]);
  }

  useEffect(() => {
    const getKey = (condition: Condition) => `${condition.type}-${condition.field}`;
    const hasDuplicate = conditions.length !== new Set(conditions.map(getKey)).size;
    const isValid = conditions.every(c => {
      const option = options.find(o => o.type === c.type);
      if (!option) {
        return false;
      }

      if (option.fieldType === "json") {
        return c.field && c.value;
      }

      return c.value;
    });
    setIsFormValid(!hasDuplicate && isValid);

  }, [conditions]);

  return (
    <div>
      <Modal
        isOpen={true}
        onClose={onClose}
        width="small"
        >
        <Modal.Header border>Edit Conditions for {role.name}</Modal.Header>
        <Modal.Content>
          <Stack direction="column" gap="medium" padding="medium" block>
            <Typography large>Condition</Typography>
            {
              conditions?.map((condition, index) => (
                <FieldCondition
                  key={index}
                  fieldOptions={getAvailableOptions(condition, conditions, options)}
                  condition={condition}
                  onUpdate={(condition) => handleUpdate(condition, index)}
                  onRemove={() => handleRemove(index)}
                  index={index}></FieldCondition>
              ))
            }
            <Button type="ghost" icon={<AddIcon/>} onClick={addNewCondition}>Add Condition</Button>
          </Stack>
        </Modal.Content>
        <Modal.Footer>
          <Stack direction="row" gap="8" justify="end">
            <Button onClick={onClose} type="tertiary" size="large">
              Cancel
            </Button>
            <Button
              disabled={!isFormValid || !isModified}
              onClick={() => onSave(conditions)}
              type="primary"
              size="large"
            >
              Save
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function getAvailableOptions(currentCondition: Condition, conditions: Condition[], fieldOptions: FieldOption[]) {
  return fieldOptions.filter((option) => {
    const isCurrentCondition = currentCondition.type === option.type;
    const isExistingCondition = conditions.map((condition) => condition.type).includes(option.type);
    const isJsonField = option.fieldType === "json";

    // can have multiple json fields
    return isCurrentCondition || (isJsonField || !isExistingCondition);
  });
}

type FieldConditionProps = {
  condition: Condition;
  fieldOptions: FieldOption[];
  index: number;
  onUpdate: (condition: Condition) => void;
  onRemove: () => void;
}

function FieldCondition({ condition, fieldOptions, index, onUpdate, onRemove }: FieldConditionProps) {
  const currentOption = fieldOptions.find((option) => option.type === condition.type);
  const handleSelectChange = (value: string) => {
    onUpdate({
      ...condition,
      type: value
    });
  }

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      ...condition,
      field: e.target.value
    });
  }

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      ...condition,
      value: e.target.value
    });
  }

  return (
    <Stack direction="row" gap="4" align={"center"} block>
      <div style={{width: "48px"}}>
        {index === 0 ? "Where" : "And"}
      </div>
      <InputSelect width={160} selectedValue={condition.type} setSelectedValue={handleSelectChange}>
        {fieldOptions.map((option, index) => (
          <InputSelect.Option key={index} value={option.type}>{option.name}</InputSelect.Option>
        ))}
      </InputSelect>
      {currentOption?.fieldType === "json" && (
        <>
          <div style={{ width: "6px", textAlign: "center"}}>.</div>
          <InputText width={150} block value={condition.field} placeholder={"field"} onChange={handleFieldChange}/>
        </>
      )}
      <div style={{ width: "6px", textAlign: "center"}}>
        =
      </div>
      <InputText block width={150} value={condition.value} placeholder={"has value"} onChange={handleValueChange}/>
      <Stack direction="row" gap="4" justify={"end"} flex>
        <Button type="ghost" tone={"danger"} size="small" icon={<DeleteIcon/>} onClick={onRemove}
                tooltip={{
                  label: "Remove condition",
                  position: "bottom"
                }}
        />
      </Stack>
    </Stack>
  );
}
