import { auth } from "../auth/Auth";
import { SHOW_PROGRESS } from "./progress";
import { axiosProxy } from "../axios/AxiosProxy";

export const CALLSTATS_RECEIVED = "CALLSTATS_RECEIVED";
export const CALLSTAT_DETAILS_RECEIVED = "CALLSTAT_DETAILS_RECEIVED";
export const ERROR_CALLSTATS_RETRIEVAL = "ERROR_CALLSTATS_RETRIEVAL";
export const RESET_STATE_CALL_STATS = "RESET_STATE_CALL_STATS";

export const showProgress = () => ({
  type: SHOW_PROGRESS
});

export const callStatsReceived = (calls, recordCount) => ({
  type: CALLSTATS_RECEIVED,
  calls: calls,
  recordCount: recordCount
});

export const callStatDetailsReceived = (log, recordCount) => ({
  type: CALLSTAT_DETAILS_RECEIVED,
  log: log,
  recordCount: recordCount
});

export const errorCallStatsRetrieval = () => ({
  type: ERROR_CALLSTATS_RETRIEVAL
});

//redux-thunk
export const fetchCallStats = criteria => {
  return dispatch => {
    dispatch(showProgress());
    return getCallStats(criteria)
      .then(resp => {
        const recCount = Number(resp.headers["recordcount"]);
        let validatedResults = validateHistory(resp.data);
        dispatch(callStatsReceived(validatedResults, recCount));
      })
      .catch(() => {
        dispatch(errorCallStatsRetrieval());
      });
  };
};

const getCallStats = criteria => {
  return axiosProxy
    .getInstance()
    .get(
      "/groups/" +
        auth.getCurrentGroupId() +
        "/callstats?criteria=" +
        JSON.stringify(criteria)
    );
};

export const fetchCallStatDetails = (contactId, criteria) => {
  return dispatch => {
    dispatch(showProgress());
    return getCallStatDetails(contactId, criteria)
      .then(resp => {
        const recCount = resp.headers["recordcount"];
        let validatedResults = validateHistory(resp.data);
        dispatch(callStatDetailsReceived(validatedResults, recCount));
      })
      .catch(() => {
        dispatch(errorCallStatsRetrieval());
      });
  };
};

const getCallStatDetails = (contactId, criteria) => {
  return axiosProxy
    .getInstance()
    .get(
      "/groups/" +
        auth.getCurrentGroupId() +
        "/callstatdetails/" +
        contactId +
        "?criteria=" +
        JSON.stringify(criteria)
    );
};

const validateHistory = data => {
  let validatedResults = [];
  if (data) {
    data.map(item => {
      let result = item._source;
      validatedResults.push(result);
    });
  }

  return validatedResults;
};

export const resetStateCallStats = () => ({
  type: RESET_STATE_CALL_STATS
});
