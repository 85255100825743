import { GSM_CONFIGS_CHANGE_TAB } from "../actions/gsmconfigs";

const gsmconfigs = (
  state = {
    tab: "gsmconfigs"
  },
  action
) => {
  switch (action.type) {
    case GSM_CONFIGS_CHANGE_TAB:
      return Object.assign({}, state, { tab: action.tab });
    default:
      return state;
  }
};

export default gsmconfigs;
