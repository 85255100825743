import React from "react";
import JobDetails from "./JobDetails";
import Telephony from "./Telephony";
import AX from "./AX";
import CX from "./CX";
import Network from "./Network";
import * as PropTypes from "prop-types";

const customerToConnectReference = 4.5;
const agentToConnectReference = 4.8;

function calculateDegradation(reference, score) {
  let percentage = ((reference - score) / reference) * 100;

  return percentage.toFixed(2);
}

function Results(props) {
  const { jobStats, insights } = props;

  let job;
  let tags;
  if (jobStats) {
    job = jobStats.job;
    tags = jobStats.tags;
  }

  return (
    <>
      {
        <>
          <JobDetails job={job} tags={tags} insights={insights} />

          <Telephony
            jobStats={jobStats}
            calculateDegradation={calculateDegradation}
            customerToConnectReference={customerToConnectReference}
            insights={insights}
          />

          <AX
            jobStats={jobStats}
            calculateDegradation={calculateDegradation}
            agentToConnectReference={agentToConnectReference}
          />

          <CX
            jobStats={jobStats}
            calculateDegradation={calculateDegradation}
            customerToConnectReference={customerToConnectReference}
          />

          <Network jobStats={jobStats} />
        </>
      }
    </>
  );
}

Results.propTypes = {
  jobStats: PropTypes.object,
  calculateDegradation: PropTypes.func,
  customerToConnectReference: PropTypes.number,
  insights: PropTypes.object
};

export default Results;
